<template>
	<v-row>
		<br-generic-list-base :derived-component="_self">
			<template #data-table-area>
				<br-generic-list-base-data-table :list-component="_self">
					<template #item.is_paid="{ rowInfo, colInfo, modelField, cellDefaultContent }">
						<v-icon v-if="modelField.val" color="success">mdi-check</v-icon>
						<v-icon v-else color="error">mdi-close</v-icon>
					</template>
				</br-generic-list-base-data-table>
			</template>
		</br-generic-list-base>
	</v-row>
</template>

<script>
	
	import { B_REST_Vuetify_GenericList } from "@/bREST/core/implementations/vue";
	
	export default {
		name: "invoiceList",
		mixins: B_REST_Vuetify_GenericList.createMixin({
			formComponent: () => import("./InvoiceForm.vue"),
			modelName: "Invoice",
			fromLoader: {
				apiBaseUrl: "/invoices/",
			},
			cols: {
				"date":    {fieldNamePaths:"dueDate",       style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT}},
				"type":    {fieldNamePaths:"type",          style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT}},
				"amount":  {fieldNamePaths:"total",         style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT}},
				"is_paid": {fieldNamePaths:"payment_paidD", style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT}},
			},
			globalActions: {
				...B_REST_Vuetify_GenericList.GlobalAction.defineCommonAction_add({}),
			},
			row: {
				checkbox: {isEnabled:true},
				actions: {
					...B_REST_Vuetify_GenericList.RowAction.defineCommonAction_delete({
						isEnabled(action,model,isCtrlClickOrMiddleClick)
						{
							if (!this.$bREST.userType_isAdmin) { return "perms"; }
							return model.canDelete;
						},
					}),
					...B_REST_Vuetify_GenericList.RowAction.defineCommonAction_edit({icon:"mdi-eye"}),
					sendPrint: {
						click: {
							isEnabled(action,model) { 
								return !!model.select("citizen.email_address").isNotEmpty_andValid;
							},
							async hook(action,selectedModels,isCtrlClickOrMiddleClick) { 
								return this.$bREST.invoice_doSendPrintAction(selectedModels.pk,'sendInvoice'); 
							},
						},
						icon: "mdi-email",
					}
				},
			},
			filters: {
				calc_isPaid: {fieldNamePath:"payment_paidD",op:B_REST_Vuetify_GenericList.FILTER_OPS.OP_NULL},
			},
		}),
	};
	
</script>