<template>
	<br-generic-form-base :derived-component="_self">
		
		<template #fields>
			<v-row>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="firstName" /> </v-col>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="lastName" /> </v-col>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="userName" /> </v-col>
				<v-col cols="12" md="6"> <br-field-db-recovery-email :model="model" /> </v-col>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="pwd_dbHash" /> </v-col>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="type" /> </v-col>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="isEnabled" as="checkbox" /> </v-col>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="color" as="colorPicker" /> </v-col>
			</v-row>
		</template>
		
	</br-generic-form-base>
</template>

<script>
	
	import { B_REST_Vuetify_GenericFormBase_createMixin } from "@/bREST/core/implementations/vue/vuetifyComponents/genericModules/form/BrGenericFormBase.vue";
	
	
	
	export default {
		name: "userForm",
		mixins: B_REST_Vuetify_GenericFormBase_createMixin({
			modelName:          "User",
			apiBaseUrl:         "/users/",
			showValidationErrs: true,
			showSkeletonLoader: true,
		  //autoUpdateInterval: 2000,
			requiredFields:     "<all>",
			todos: [
				//Arr of {isDone,isBug,text} that will appear automatically at the top of the form
			],
			async modelReady()
			{
				if (this.model.isNew)
				{
					this.model.fromObj({
						type:      this.$bREST.consts.userTypes.ADMIN,
						lang:      "fr",
						isEnabled: true,
					});
				}
			},
			async afterLoad(response,models)                 { },
			async customValidator()                          { },
			async beforeSave(request,model)                  { },
			async afterSave(response,model,isSuccess,wasNew) { },
		}),
	}
	
</script>	