<template>
	<invoice-card-new      v-if="isNew" :citizen_pk="citizen_pk" :contract_pk="contract_pk" />
	<invoice-card-existing v-else       :invoice_pk="invoice_pk" />
</template>

<script>
	
	export default {
		components: {
			InvoiceCardNew:      () => import("./InvoiceCardNew.vue"),
			InvoiceCardExisting: () => import("./InvoiceCardExisting.vue"),
		},
		computed: {
			citizen_pk()  { return this.$bREST.routes_current_pathVars_parent_pkTag  || this.$bREST.throwEx(`Expected a citizen PK`);      }, //Expecting a route like /citizens/:citizen/invoices/:pkTag
			contract_pk() { return this.$bREST.routes_current_qsa?.contract          || null;                                              }, //We can create invoices from ContractForm.vue
			invoice_pk()  { return this.$bREST.routes_current_pathVars_sub_pkTag     || this.$bREST.routes_current_pathVars_pkTag || null; }, //A pk or "*", expecting a route like /citizens/:citizen/invoices/:pkTag or  /invoices/:pkTag
			isNew()       { return this.invoice_pk==="*";                                                                                  },
		},
	};
	
</script>