<template>
	<v-row><br-generic-list-base :derived-component="_self">
		</br-generic-list-base>
	</v-row>
</template>

<script>
	
	import { B_REST_Vuetify_GenericList } from "@/bREST/core/implementations/vue";
	
	export default {
		name: "animalInShelterList",
		mixins: B_REST_Vuetify_GenericList.createMixin({
			formComponent: () => import("./AnimalInShelterForm.vue"),
			modelName: "AnimalInShelter",
			fromLoader: {
				apiBaseUrl: "/animalsInShelter/",
			},
			cols: {
				arrival_d: {fieldNamePaths:"arrival_d",                            style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT}},
				medal:     {fieldNamePaths:"animal.calc_currentConcatMedalNumber", style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT}},
				room:      {fieldNamePaths:"room",                                 style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT}},
				status:    {fieldNamePaths:"status",                               style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT}},
			},
			globalActions: {
				...B_REST_Vuetify_GenericList.GlobalAction.defineCommonAction_add({}),
			},
			row: {
				checkbox: {isEnabled:true},
				actions: {
					...B_REST_Vuetify_GenericList.RowAction.defineCommonAction_delete({
						isEnabled(action,model,isCtrlClickOrMiddleClick)
						{
							if (!this.$bREST.userType_isAdmin) { return "perms"; }
							return model.canDelete;
						},
					}),
					...B_REST_Vuetify_GenericList.RowAction.defineCommonAction_edit({}),
				},
			},
			filters: {
				owner_allMedalNumbers:     {fieldNamePath:"owner.calc_concatMedalNumbers_history"},
				owner_pk:                  {fieldNamePath:"owner.pk"},
				room:                      {fieldNamePath:"room"},
				status:                    {fieldNamePath:"status"},
				animal_specie:             {fieldNamePath:"animal.specie"},
				animal_mainBreed:          {fieldNamePath:"animal.main_breed_fk", items:"allBreedList"},
				animal_crossBreed:         {fieldNamePath:"animal.cross_breed_fk", items:"allBreedList"},
				animal_primaryHairColor:   {fieldNamePath:"animal.hair_color_primary_fk", items:"hairColorList"},
				animal_secondaryHairColor: {fieldNamePath:"animal.hair_color_secondary_fk", items:"hairColorList"},
				animal_weight:             {fieldNamePath:"animal.weight"},
				animal_sex:                {fieldNamePath:"animal.sex"},
				owner_civicNumber:         {fieldNamePath:"owner.civic_number"},
				owner_street:              {fieldNamePath:"owner.municipality_street_fk", picker:"municipalityStreetList"},
				owner_municipality:        {fieldNamePath:"owner.municipality_fk", items:"municipalityList"},
			},
		}),
		created()
		{
			this.$bREST.utils.console_todo([`Street filter supposed to be a picker of street per municipality, but it'll be done later in the proj`]);
		},
	};
	
</script>
