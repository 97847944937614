<template>
	<v-row>
		<br-generic-list-base :derived-component="_self" />
	</v-row>
</template>

<script>
	
	import { B_REST_Vuetify_GenericList } from "@/bREST/core/implementations/vue";
	
	export default {
		name: "userList",
		mixins: B_REST_Vuetify_GenericList.createMixin({
			formComponent: () => import("./UserForm.vue"),
			modelName: "User",
			fromLoader: {
				apiBaseUrl: "/users/",
			},
			cols: {
				"firstName": {fieldNamePaths:"firstName", style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT}},
				"lastName":  {fieldNamePaths:"lastName",  style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT}},
			},
			globalActions: {
				...B_REST_Vuetify_GenericList.GlobalAction.defineCommonAction_add({}),
			},
			row: {
				checkbox: {isEnabled:true},
				actions: {
					...B_REST_Vuetify_GenericList.RowAction.defineCommonAction_delete({
						isEnabled(action,model,isCtrlClickOrMiddleClick)
						{
							if (!this.$bREST.userType_isAdmin) { return "perms"; }
							return model.canDelete;
						},
					}),
					...B_REST_Vuetify_GenericList.RowAction.defineCommonAction_edit({}),
				}
			},
			filters: {
				firstName: {fieldNamePath:"firstName"},
				lastName:  {fieldNamePath:"lastName"},
			},
		}),
	};
	
</script>