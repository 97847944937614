
import { B_REST_Utils, B_REST_Model } from "@/bREST/core/classes";
import { B_REST_VueApp_base, B_REST_Vuetify_PickerDef } from "@/bREST/core/implementations/vue";
import B_REST_Descriptor from "@/bREST/core/classes/descriptors/B_REST_Descriptor.js";


//NOTE: If we need to add new langs later, will need to add for frontend & backend's core & custom locs + imports for Vuetify locale (Check B_REST_App_base.js)
import locMsgs_custom_fr from "./loc/fr.json";

import App from "./App.vue";

import PublicLayoutComponent from "./layouts/PublicLayout.vue";
import MainLayoutComponent   from "./layouts/MainLayout.vue";

// For input mask functionality (like filling in the non-numerical chars in a phone number)
// see https://www.npmjs.com/package/vue-input-facade, https://ronaldjerez.github.io/vue-input-facade/
import InputFacade from 'vue-input-facade';
import Vue from "vue";
Vue.use(InputFacade);

//WARNING: Weird that half setup is as obj in constructor and the rest is as abstract methods

const debugErrs = process.env.VUE_APP_B_REST_DEBUG_ERRS==1;



class MyApp extends B_REST_VueApp_base
{
	static get COLORS_SUCCESS()        { return {accent1:"#b9f6ca",accent2:"#69f0ae",accent3:"#00e676",accent4:"#00c853",base:"#4caf50",darken1:"#43a047",darken2:"#388e3c",darken3:"#2e7d32",darken4:"#1b5e20",lighten1:"#66bb6a",lighten2:"#81c784",lighten3:"#a5d6a7",lighten4:"#c8e6c9",lighten5:"#e8f5e9"}; } //Equals to vuetify/lib/util/colors's vuetify_colors.green
	static get COLORS_WARNING()        { return {accent1:"#ffd180",accent2:"#ffab40",accent3:"#ff9100",accent4:"#ff6d00",base:"#ff9800",darken1:"#fb8c00",darken2:"#f57c00",darken3:"#ef6c00",darken4:"#e65100",lighten1:"#ffa726",lighten2:"#ffb74d",lighten3:"#ffcc80",lighten4:"#ffe0b2",lighten5:"#fff3e0"}; } //Equals to vuetify/lib/util/colors's vuetify_colors.orange
	static get COLORS_ERROR()          { return {accent1:"#ff8a80",accent2:"#ff5252",accent3:"#ff1744",accent4:"#d50000",base:"#f44336",darken1:"#e53935",darken2:"#d32f2f",darken3:"#c62828",darken4:"#b71c1c",lighten1:"#ef5350",lighten2:"#e57373",lighten3:"#ef9a9a",lighten4:"#ffcdd2",lighten5:"#ffebee"}; } //Equals to vuetify/lib/util/colors's vuetify_colors.red
	static get COLORS_PRIMARY_DARK()   { return "#30384e"; } //NOTE: Logo's bg color actually is #1a1e2a, but Math said it's too dark
	static get COLORS_SECONDARY_DARK() { return "#bc955a"; }
	
	_shouldShowBackNavBtn = false; //For when we go from a list to a form, or ex citizen form to sub thing. WARNING: For now, not accurate, because if we start in a sub page, we have no nav triggering calculating this
	
	
	constructor()
	{
		super({
			version: "1.6", //NOTE: Must also update server's bREST_Custom::CUSTOM_VERSION
								/*
								NOTES:
									-Core version control:
										-Frontend: in /bREST/core/version.js
										-Server:   in /bREST/bREST_base::VERSION & /bREST/version.info
									-Custom version control:
										-Frontend: in App.js constructor props
										-Server:   in bREST_Custom::CUSTOM_VERSION
								*/
			api: {
				baseURL: process.env.VUE_APP_B_REST_BASE_URL,
				mockCalls_enabled: false,
			},
			flags: {
				heartbeat_freq_secs:     false, //NOTE: Could maybe read from server on boot (like server version), so even if apps are cached, server could force, but why would freq evolve ?
				heartbeat_authOnly:      false,
				defaultPagingSize:       15,
				debug_locPaths:          false,
				debug_fieldNamePaths:    false,
				debug_responses:         false,
				debug_beforeReload:      false,
				debug_ignorePerms:       false,
				debug_authReloadErrs:    debugErrs,
				onErr_breakpoint:        debugErrs,
				onErr_showNativeAlert:   debugErrs ? B_REST_Utils.FLAGS_ON_ERR_SHOW_NATIVE_ALERT_ONCE : B_REST_Utils.FLAGS_ON_ERR_SHOW_NATIVE_ALERT_NEVER,
				onErr_overlayDomTree:    debugErrs,
				genericForm_showTodos:   process.env.VUE_APP_B_REST_GENERIC_FORM_SHOW_TODOS==="1",
				autoVerifyRecoveryEmail: false,
				console_todo:            false,
				console_info:            false,
				console_warn:            true,
				console_error:           true,
				boot_cache:              false,
			},
			crypto: {
				algo: process.env.VUE_APP_B_REST_CRYPTO_ALGO,
				salt: process.env.VUE_APP_B_REST_CRYPTO_SALT,
			},
			appLangs: {
				fr: locMsgs_custom_fr,
			},
			appComponent: App,
			globalCSSVars: {
				"--bREST-BrFieldDb_isDirty_color": "#82b1ff",
			},
			brFieldDbAttrs:   {}, //Ex in CPA we do {outlined:true,rounded:true,dense:true}
			brFieldFileAttrs: {},
			vuetifyThemeOptions: {
				dark: false,
				themes: {
					light: {
						success: MyApp.COLORS_SUCCESS,
						warning: MyApp.COLORS_WARNING,
						error:   MyApp.COLORS_ERROR,
						
						primary:    MyApp.COLORS_PRIMARY_DARK,
						secondary:  MyApp.COLORS_SECONDARY_DARK,
						background: "#eeeeee",
						
						dark: MyApp.COLORS_PRIMARY_DARK,
					},
					dark: {
						success: MyApp.COLORS_SUCCESS,
						warning: MyApp.COLORS_WARNING,
						error:   MyApp.COLORS_ERROR,
						
						primary:    MyApp.COLORS_SECONDARY_DARK, //NOTE: flipping primary & secondary here
						secondary:  "#1a237e",
						background: MyApp.COLORS_PRIMARY_DARK,
					},
				},
				options: {
					customProperties: true
				}
			},
			pickerDefs: {
				citizenList: {
					component_ifGenericList_moduleName: "citizen",
					reuseMode: B_REST_Vuetify_PickerDef.REUSE_MODE_IF_NOT_PROMPTING,
				},
				interventionList: {
					component_ifGenericList_moduleName: "intervention",
					reuseMode: B_REST_Vuetify_PickerDef.REUSE_MODE_IF_NOT_PROMPTING,
				},
				animalList: {
					component_ifGenericList_moduleName: "animal",
					reuseMode: B_REST_Vuetify_PickerDef.REUSE_MODE_IF_NOT_PROMPTING,
				},
				municipalityStreetList: {
					component_ifGenericList_moduleName: "municipalityStreet",
					reuseMode: B_REST_Vuetify_PickerDef.REUSE_MODE_IF_NOT_PROMPTING,
				},
			},
		});
		
		this._constructor_mountVue_install_$bREST(); //Check method docs for what that does. Don't rem or place before super()
	}

	static get consts() { return  this._consts; }
	       get consts() { return MyApp._consts; }
	static _consts = {
		//NOTE: bREST_Custom::TAXES_x must match w App.js::consts.taxes.x
		taxes: {
			gst: 0.05,
			pst: 0.09975,
		},
		animalSpecies: {
			DOG: 'dog',
			CAT: 'cat',
			OTHER: 'other',
		},
		animalLicenseCostTypes: {
			AMOUNT:        "amount",
			FREE_TRANSFER: "free_transfer",
			FREE_ASSIST:   "free_assist",
		},
		animalStatuses: {
			ACTIVE: "active",
			DEAD_NO_PROOF: "dead_no_proof",
			DEAD_WITH_PROOF: "dead_with_proof",
			ABANDONNED: "abandonned",
			PASSIVE: "passive",
			LEGAL: "legal",
			AWAIT_EXAM: "await_exam",
			AWAIT_ADOPTION: "await_adoption",
			AWAIT_EUTHANASIA: "await_euthanasia",
			AWAIT_SURGERY: "await_surgery",
			AWAIT_CLAIM: "await_claim",
			ISOLATION_TREATMENT: "isolation_treatment",
			ISOLATION_BEHAVIOR: "isolation_behavior",
			QUARANTINE: "quarantine",
			WANDERING: "wandering",
			EXIT: "exit",
		},
		citizenContactMethods: {
			PHONE: "phone",
			EMAIL: "email",
		},
		contractTypes: {
			ADOPTION: "adoption",
			ABANDONMENT: "abandonment",
			RECLAMATION: "reclamation",
			SEARCH: "search",
			NON_COMPLIANCE: "nonCompliance",
			INFRACTION: "infraction",
		},
		interventionReferralOrigins: {
			WILDLIFE: "wildlife",
			MAPAQ: "mapaq",
			SQ: "sq",
			CITY: "city",
			PUBLIC_WORKS: "public_works",
			FIREFIGHTERS: "firefighters",
			OTHER: "other",
		},
		interventionLocationTypes: {
			NEIGHBORHOOD: "neighborhood",
			SPA: "spa",
			PLAINTIFF: "plaintiff",
			RECIPIENT: "recipient",
		},
		interventionPlaintiffTypes: {
			CITIZEN: "citizen",
			ANONYMOUS: "anonymous",
			NONE: "none",
			ORGANISATION: "organisation",
		},
		interventionLocationRefs: {
			PERSON:  "person",
			CITIZEN:  "citizen",
			UNKNOWN:  "unknown",
			NONE:  "none",
		},
		userTypes: {
			ADMIN:     "admin",
			RECEPTION: "reception",
			PATROL:    "patrol",
			TECH:      "tech",
			WRANGLER:  "wrangler",
			CLIENT:    "client",
			//Must match w server's Model_User::TYPE_x
		},
		licensePaymentStatuses: {
			PAYMENT_STATUS_FREE:    "free",
			PAYMENT_STATUS_PENDING: "pending",
			PAYMENT_STATUS_PAID:    "paid",
		},
		invoiceTypes: {
			TYPE_RENEWAL: "renewal",
			TYPE_OTHER:   "other",
		},
		municipalityPricingInfoTypes: {
			REQ:  "req",
			OPT:  "opt",
			NONE: "none",
		},
		COMPANY_NAME: "Spa Drummondville",
		GMAP_UPDATE_POSITION_DURATION: 5 * 60 * 1000,
	};
	
	get shouldShowBackNavBtn() { return this._shouldShowBackNavBtn; }
	
	get userType_isAdmin()     { return this.user_type_is(MyApp._consts.userTypes.ADMIN);     }
	get userType_isReception() { return this.user_type_is(MyApp._consts.userTypes.RECEPTION); }
	get userType_isPatrol()    { return this.user_type_is(MyApp._consts.userTypes.PATROL);    }
	get userType_isTech()      { return this.user_type_is(MyApp._consts.userTypes.TECH);      }
	get userType_isWrangler()  { return this.user_type_is(MyApp._consts.userTypes.WRANGLER);  }
	get userType_isClient()    { return this.user_type_is(MyApp._consts.userTypes.CLIENT);    }
	
	//For Model_Article & Model_ArticleLine
	taxes_isTaxable(articleOrInvoiceLineModel, which) //gst | pst | any
	{
		this.utils.instance_isOfClass_assert(B_REST_Model, articleOrInvoiceLineModel);
		
		const has_gst = articleOrInvoiceLineModel.select("has_gst").val;
		const has_pst = articleOrInvoiceLineModel.select("has_pst").val;
		
		if (which==="gst") { return has_gst; }
		if (which==="pst") { return has_pst; }
		return has_gst || has_pst;
	}
	taxes_calc_gst(amount) { return amount * this.consts.taxes.gst; }
	taxes_calc_pst(amount) { return amount * this.consts.taxes.pst; }
	
	
	
	_abstract_routes_defineRoutes()
	{
		this._routes_define_x_setCurrentLayoutComponent(PublicLayoutComponent);
			this._routes_define_404(     "/404",    ()=>import("./routerViews/404/Index.vue"));
			this._routes_define_403(     "/403",    ()=>import("./routerViews/403/Index.vue"));
			this._routes_define_login(   "/login/", ()=>import("./routerViews/login/Index.vue"));
			this._routes_define_resetPwd("/reset/", ()=>import("./routerViews/resetPwd/Index.vue")); //WARNING: URL must match server's RouteParser_x::_abstract_sendResettingPwdEmail_getFrontendRoutePaths() URLs
			this._routes_define_public("publicInvoicePaymentPage", "/extranet/facture/:invoice?", ()=>import("./routerViews/public/invoice/PublicInvoicePaymentPage.vue"));
			this._routes_define_public("sandbox", "/sandbox/", ()=>import("./routerViews/sandbox/Index.vue"));
			this._routes_define_public("sandboxmap", "/sandboxgmap/", ()=>import("./routerViews/sandbox/GMap.vue")); 
			this._routes_define_public("signature", "/signature/", ()=>import("./routerViews/sandbox/Signature.vue"));
		
		this._routes_define_x_setCurrentLayoutComponent(MainLayoutComponent);
			
			//NOTE: Missing reports, invoice items management & municipality management
			
			this._routes_define_genericListFormModule("user",                   "/users/");
			this._routes_define_genericListFormModule("citizen",                "/citizens/");
			this._routes_define_genericListFormModule("animal",                 "/animals/");
			this._routes_define_genericListFormModule("intervention",           "/interventions/");
			this._routes_define_genericListFormModule("animalInShelter",        "/animalsInShelter/");
			this._routes_define_genericListFormModule("equipmentRental",        "/equipmentRentals/");
			this._routes_define_genericListFormModule("invoice",                "/invoices/");
			this._routes_define_genericListFormModule("license",                "/licenses/");
			this._routes_define_genericListFormModule("contract",               "/contracts/");
			this._routes_define_genericListFormModule("postalMailInvoiceBatch", "/postalMailInvoiceBatches/");
			
			this._routes_define_genericListFormModule_listOnly("municipalityStreet", "/postalMailInvoiceBatches/");
			
			this._routes_define_auth("reports", {fr:"/rapports/",en:"/reports/"}, ()=>import("./routerViews/reports/Index.vue"));
			this._routes_define_auth("dashboard", {fr:"/tableauDeBord/",en:"/dashboard/"}, ()=>import("./routerViews/reports/Dashboard.vue"));
			
			this._routes_define_genericListFormSubModule("citizen>animal");
			this._routes_define_genericListFormSubModule("citizen>intervention");
			this._routes_define_genericListFormSubModule("citizen>invoice");
			this._routes_define_genericListFormSubModule("citizen>license");
			this._routes_define_genericListFormSubModule("citizen>contract");
			
			this._routes_define_auth("animalMedal-list", "/animals/:animal/medals/", ()=>import("./routerViews/modules/medal/MedalList.vue"), /*meta*/{}, /*transferComponentProps_forRouteInfo*/(routeInfo) => {
				return {
					showTitle: true,
					//Used for BrGenericListBase.vue::fromLoader(). check its docs
					fromLoader: {
						routeInfo,
						apiBaseUrl: "/animals/{animal}/medals",
						apiBaseUrl_path_vars: {animal:routeInfo.pathVars.animal},
						reloader: null,
					},
				};
			});
	}
	
	
	
	async _abstract_boot_await(response, corePropsThenDirectives)
	{
		
	}
	_abstract_commonDefs_setupDescriptorHooks()
	{	
		const citizenDescriptor = B_REST_Descriptor.commonDefs_get("Citizen");
					
		citizenDescriptor.validation_custom_fastFuncs_add((model) =>
		{
			const email                 = model.select("email_address");
			const email_isContactMethod = model.select("contact_method");
			
			// If email is empty & preferred contact method is email
			if (email.isEmpty && email_isContactMethod.val==this.consts.citizenContactMethods.EMAIL)
			{
				email.validation_custom_errorMsg = this.t_custom('descriptors.Citizen.contactMethodEmailNotSet');
			}
			else
			{
				email.validation_custom_errorMsg = null;
			}
		});
	}
	_abstract_beforeUnload_generalHook()
	{
		return true;
	}
	_abstract_perms_can(tag,details=null)
	{
		return !!this._perms[tag];
	}
	_abstract_user_createFromObj(userModel, userObj)
	{
		if (!userModel.select("type").val)
		{
			userModel.select("type").val = null; //Used to be: "this.consts.userTypes.CLIENT", but it seems it works anyways w just NULL, even if we go to a public page like login / reset
		}
	}
	get _abstract_user_displayName()
	{
		return this._user?.select_firstNonEmptyVal("firstName+lastName|firstName|lastName|userName|recoveryEmail") || "Anonyme";
	}
	async _abstract_login_sudoX_beforeCall(request,extraData=null)
	{
		
	}
	async _abstract_calls_interceptCoreProps_customDataNode(customProps,thenDirectives)
	{
		
	}
	async _abstract_calls_tweakResponse_hook(response, corePropsThenDirectives)
	{
		
	}
	_abstract_calls_afterCall_general_handler(response)
	{
		
	}
	get _abstract_locale_lang_fallback() { return "fr"; } //IMPORTANT: Server's bREST_Custom::_abstract_defaultLang() & frontend's App.js::_abstract_locale_lang_fallback() must match
	async _abstract_routes_hasPerms(routeInfo)
	{
		return true;
	}
	_abstract_routes_authDefaultRouteName()
	{
		/*
		Ex:
			B_REST_VueApp_RouteDef.NAME_PROFILE
			B_REST_VueApp_RouteDef.NAME_LANDPAGE
			"user-list"
			"someOtherModule-list"
		*/
		
		return "intervention-list"; //NOTE: Should match backend's bREST_Custom::_abstract_uiRoutes_defaultRouteName_auth()
	}
	/*
	Check B_REST_App_base::_abstract_routes_beforeNavigationChange() docs; ret bool or another B_REST_VueApp_RouteInfo instance
	Also use this to await loading extra data etc
	*/
	async _abstract_routes_beforeNavigationChange(routeInfo_to_proposedAction, routeInfo_to_intended, routeInfo_from=null)
	{
		return true;
	}
	_abstract_routes_evalTravelDirection(travelDir,routeInfo_to,routeInfo_from=null)
	{
		this._shouldShowBackNavBtn = travelDir===MyApp.ROUTES_TRAVEL_DIR_TO_CHILD; //WARNING: For now, not accurate, because if we start in a sub page, we have no nav triggering calculating this, or maybe we should just always be able to go back
		
		return travelDir;
	}
	_abstract_routes_afterNavigationChange(routeInfo_to, routeInfo_from=null)
	{
		
	}
	
	async invoice_doSendPrintAction(invoice_pk, which) {
		const yieldsFile = ["printInvoice", "printReceipt"].includes(which);
		const request = new this[yieldsFile ? "POST_File" : "POST"]("/invoices/{pkTag}/{action}", { pkTag: invoice_pk, action: which });
		if (yieldsFile) {
			request.expectsContentType_pdf();
			await this.call_download(request); //Might throw
		} else {
			request.expectsContentType_json();
			const response = await this.call(request); //Might throw
			const result = response.data.count_could === 1 ? "success" : "error";
			this.notifs_tmp({ msg: this.t_custom(`actions.${which}.${result}`), color: result });
		}
	}
	
	async contract_doSendPrintAction(contract_pk) {
		const request = new this.POST("/contracts/{pkTag}/sendContracts", { pkTag: contract_pk });
		
		request.expectsContentType_json();
		const response = await this.call(request); // Might throw
		
		const result = response.data.count_could === 1 ? "success" : "error";
		this.notifs_tmp({ msg: this.t_custom(`actions.sendContract.${result}`), color: result });
	}
};




MyApp.instance_init();
