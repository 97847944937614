<template>
	<br-generic-list-base :derived-component="_self" />
</template>

<script>
	
	import { B_REST_Vuetify_GenericList } from "@/bREST/core/implementations/vue";
	
	
	
	export default {
  		name: "contractList",
		mixins: B_REST_Vuetify_GenericList.createMixin({
			formComponent: () => import("./ContractForm.vue"),
			modelName: "Contract",
			fromLoader: {
				apiBaseUrl: "/contracts/",
			},
			cols: {
				"type": {fieldNamePaths:"type", style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT}},
				"date":   {fieldNamePaths:"createdDT",            style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT}},
				"status": {
					fieldNamePaths:"completion_date",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
					toCellContent(col,model) { return model.select("completion_date").isEmpty ? this.t("cols.status.ongoing") : this.t("cols.status.done"); },
				},
			},
			globalActions: {
				...B_REST_Vuetify_GenericList.GlobalAction.defineCommonAction_add({}),
			},
			row: {
				checkbox: {isEnabled:true},
				actions: {
					...B_REST_Vuetify_GenericList.RowAction.defineCommonAction_delete({
						isEnabled(action,model,isCtrlClickOrMiddleClick)
						{
							if (!this.$bREST.userType_isAdmin) { return "perms"; }
							return model.canDelete;
						},
					}),
					...B_REST_Vuetify_GenericList.RowAction.defineCommonAction_edit({icon:"mdi-eye"}),
					sendPrint: {
						click: {
							isEnabled(action,model) {
								return !!model.select("citizen.email_address").isNotEmpty_andValid;
							},
							async hook(action,selectedModels,isCtrlClickOrMiddleClick) { 
								return this.$bREST.contract_doSendPrintAction(selectedModels.pk,'sendContracts'); 
							},
						},
						icon: "mdi-email",
					}
				},
			},
		}),
	};
	
</script>