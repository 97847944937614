<template>
	<br-generic-form-base :derived-component="_self">
		
		<template #fields>
			<v-row>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="arrival_d" /> </v-col>
				<v-col cols="12" md="6">
					<fk-picker-w-view-add-btns :model="model" field="intervention_fk" picker="interventionList" @picker:select="intervention_onSelect" moduleName="intervention" />
				</v-col>
				<v-col cols="12" md="6">
					<fk-picker-w-view-add-btns :model="model" field="animal_fk" picker="animalList" @picker:select="animal_onSelect" moduleName="animal" />
				</v-col>
				<v-col cols="12" md="6">
					<fk-picker-w-view-add-btns :model="model" field="owner_fk" picker="citizenList" @picker:select="owner_onSelect" moduleName="citizen" />
				</v-col>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="room" /> </v-col>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="source" as="radioGroup" /> </v-col>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="status" as="select"/> </v-col>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="quantity" /> </v-col>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="type" as="select"/> </v-col>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="description" as="textarea" /> </v-col>
			</v-row>
		</template>
		
	</br-generic-form-base>
</template>

<script>
	
	import { B_REST_Vuetify_GenericFormBase_createMixin } from "@/bREST/core/implementations/vue/vuetifyComponents/genericModules/form/BrGenericFormBase.vue";
	
	export default {
		name: "animalInShelterForm",
		components: {
			FkPickerWViewAddBtns: ()=>import("@/custom/components/FkPickerWViewAddBtns.vue"),
		},
		mixins: B_REST_Vuetify_GenericFormBase_createMixin({
			modelName:          "AnimalInShelter",
			apiBaseUrl:         "/animalsInShelter/",
			needsAccessToken:   true,
			showValidationErrs: true,
			showSkeletonLoader: true,
		 // autoUpdateInterval: 2000,
			requiredFields:     "<all>",
			todos: [
				//Arr of {isDone,isBug,text} that will appear automatically at the top of the form
			],
			async modelReady()                               { },
			async afterLoad(response,models)                 { },
			async customValidator()                          { },
			async beforeSave(request,model)                  { },
			async afterSave(response,model,isSuccess,wasNew) { },
		}),
		methods: {
			intervention_onSelect({modelField,selection})
			{
				
			},
			animal_onSelect({modelField,selection})
			{
				
			},
			owner_onSelect({modelField,selection})
			{
				
			},
		},
	}
	
</script>