<template>
	<br-generic-form-base :derived-component="_self">
		
		<template #fields>
			<equipment-rental-card :model="model" />
		</template>
		
	</br-generic-form-base>
</template>

<script>
	
	import { B_REST_Vuetify_GenericFormBase_createMixin } from "@/bREST/core/implementations/vue/vuetifyComponents/genericModules/form/BrGenericFormBase.vue";
	
	export default {
		name: "equipmentRentalForm",
		components: {
			EquipmentRentalCard: ()=>import("./EquipmentRentalCard.vue"),
		},
		mixins: B_REST_Vuetify_GenericFormBase_createMixin({
			modelName:          "EquipmentRental",
			apiBaseUrl:         "/equipmentRentals/",
			needsAccessToken:   true,
			showValidationErrs: true,
			showSkeletonLoader: true,
		 // autoUpdateInterval: 2000,
			requiredFields:     "<all>",
			todos: [
				//Arr of {isDone,isBug,text} that will appear automatically at the top of the form
			],
			async modelReady()                               { },
			async afterLoad(response,models)                 { },
			async customValidator()                          { },
			async beforeSave(request,model)                  { },
			async afterSave(response,model,isSuccess,wasNew) { },
		}),
	};
	
</script>