<template>
	<!-- Is there a way to put the prompts inside generic-list-base? -->
	<div>
		<br-generic-list-base :derived-component="_self"/>
	</div>
	
	<!--
		NOTE: Put this in a slot in the list, not out of the list
			<GmapMap :center="{lat:45.8829153, lng:-72.5255956}" :zoom="12" map-type-id="terrain" style="width: 500px; height: 300px">
				<GmapMarker :key="index" v-for="(m, index) in markers" :position="m.position" :clickable="true" :draggable="true" @click="center=m.position" />
			</GmapMap>
	-->
</template>

<script>

	
	import { B_REST_Vuetify_GenericList } from "@/bREST/core/implementations/vue";
	import { B_REST_Vuetify_Prompt, B_REST_Vuetify_Prompt_Action } from "@/bREST/core/implementations/vue/vuetifyComponents/prompt/B_REST_Vuetify_Prompt.js"; // License renewal action prompt
	
	
	export default {
		name: "citizenList",
		components: {	
			BrPrompt: () => import("@/bREST/core/implementations/vue/vuetifyComponents/prompt/BrPrompt.vue"),
		},
		mixins: B_REST_Vuetify_GenericList.createMixin({
			formComponent: () => import("./CitizenForm.vue"),
			modelName: "Citizen",
			fromLoader: {
				apiBaseUrl: "/citizens/",
			},
			cols: {
				pk: {
					fieldNamePaths: null,
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
					toCellContent(col,model) { return model.pk; },
				},
				licenceNo: {fieldNamePaths:"calc_concatMedalNumbers_current", style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT}},
				name: {
					fieldNamePaths: "<toLabel>",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				fullAddress: {
					fieldNamePaths: "civic_number|municipality_street.name|appt_number|municipality.name|postal_code",
					toCellContent(col,model) { return model.select_nonEmptyValsConcatenated("civic_number|municipality_street.name|appt_number|municipality.name|postal_code"); },
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				phones: {
					fieldNamePaths: "phone_1|phone_2",
					toCellContent(col,model) { return model.select_nonEmptyValsConcatenated("phone_1|phone_2",", "); },
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
			},
			globalActions: {
				...B_REST_Vuetify_GenericList.GlobalAction.defineCommonAction_add({}),
				citizenExport: {
					click: {
						async hook(action,selectedModels,isCtrlClickOrMiddleClick) { return this.citizenExport(); },
					},
					icon: "mdi-table-arrow-right",
					selectionType: B_REST_Vuetify_GenericList.GlobalAction.SELECTION_TYPE_0,
				}
			},
			row: {
				checkbox: {isEnabled:true},
				actions: {
					...B_REST_Vuetify_GenericList.RowAction.defineCommonAction_delete({
						isEnabled(action,model,isCtrlClickOrMiddleClick)
						{
							if (!this.$bREST.userType_isAdmin) { return "perms"; }
							
							/*
							NOTE: If we set $toObj_slowlyCascadeEvalCanDelete=true in RouteParser_Citizens::_asGenericListFormModule(), then we should do the following instead:
									return model.canDelete;
							*/
								return true;
						},
					}),
					...B_REST_Vuetify_GenericList.RowAction.defineCommonAction_edit({}),
				},
			},
			filters: {
				calc_flatSearch:                           {fieldNamePath:"calc_flatSearch"},
				pk:                                        {fieldNamePath:"pk",op:B_REST_Vuetify_GenericList.FILTER_OPS.OP_EQ_IN},
				allMedalNumbers:                           {fieldNamePath:"calc_concatMedalNumbers_history"},
				animal_medal_number:                       {fieldNamePath:"animals.medal_number", op:B_REST_Vuetify_GenericList.FILTER_OPS.OP_EQ_IN},
				animal_microchip:                          {fieldNamePath:"animals.microchip", op:B_REST_Vuetify_GenericList.FILTER_OPS.OP_EQ_IN},
				animal_specie:                             {fieldNamePath:"animals.specie"},
				animal_mainBreed:                          {fieldNamePath:"animals.main_breed_fk", items:"allBreedList"},
				animal_crossBreed:                         {fieldNamePath:"animals.cross_breed_fk", items:"allBreedList"},
				animal_primaryHairColor:                   {fieldNamePath:"animals.hair_color_primary_fk", items:"hairColorList"},
				animal_secondaryHairColor:                 {fieldNamePath:"animals.hair_color_secondary_fk", items:"hairColorList"},
				animal_weight:                             {fieldNamePath:"animals.weight"},
				animal_sex:                                {fieldNamePath:"animals.sex"},
				email_address:                             {fieldNamePath:"email_address"},
				phone1:                                    {fieldNamePath:"phone_1"},
				phone2:                                    {fieldNamePath:"phone_2"},
				civicNumber:                               {fieldNamePath:"civic_number", op:B_REST_Vuetify_GenericList.FILTER_OPS.OP_EQ_IN},
				street:                                    {fieldNamePath:"municipality_street_fk", picker:"municipalityStreetList"},
				municipality:                              {fieldNamePath:"municipality_fk", items:"municipalityList", multiple:true},
				contact_method:                            {fieldNamePath:"contact_method"},
				renewal_method:                            {fieldNamePath:"email_address", op:B_REST_Vuetify_GenericList.FILTER_OPS.OP_N_NULL}, //NOTE: We also have email_address filter, but this one has a diff meaning and needs OP_N_NULL
				calc_hasAnimalsToValidate:                 {fieldNamePath:"calc_hasAnimalsToValidate"},
				calc_isSearchingForLostAnimal:             {fieldNamePath:"calc_isSearchingForLostAnimal"},
				calc_hasDueRenewal:                        {fieldNamePath:"calc_hasDueRenewal"},
				hasUnpaidInvoices:                         {fieldNamePath:"calc_balance", op:B_REST_Vuetify_GenericList.FILTER_OPS.OP_N_0_EMPTY_STR},
				calc_hasOngoingInterventions:              {fieldNamePath:"calc_hasOngoingInterventions"},
				calc_hasInfractions:                       {fieldNamePath:"calc_hasInfractions"},
				calc_citizenFlaggedAnimalChangesOnRenewal: {fieldNamePath:"calc_citizenFlaggedAnimalChangesOnRenewal"},
				calc_hasActiveAnimals:                     {fieldNamePath:"calc_hasActiveAnimals"},
			},
		}),
		created()
		{
			this.$bREST.utils.console_todo([`Street filter supposed to be a picker of street per municipality, but it'll be done later in the proj`]);
		},
		methods: {
			async citizenExport()
			{ 
				const request = new this.$bREST.POST_File("/citizens/citizenExport");
				this.modelList.searchOptions.toQSA(request);
				request.expectsContentType_csv();
				return this.$bREST.call_download(request);  //Might throw
			},
		},
	};
	
</script>