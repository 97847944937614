<template>
	<br-generic-list-base :derived-component="_self">
		
		<template #item.name="{ rowInfo, colInfo, modelField }">
			<span>{{ rowInfo.model.select("name").val }}</span>
			<v-icon v-if="show_warningIcon(rowInfo.model)" class="ml-2">mdi-alert</v-icon>
		</template>
		
		<template #data-table-area--after>
			<legend class="mt-8 mb-2">
				<div class="text-subtitle-1 font-weight-bold">{{ t("legend.title") }}</div>
				<table>
					<tbody>
						<tr>
							<th><v-icon>mdi-alert</v-icon></th> <td>{{ t("legend.warningIcon") }}</td>
						</tr>
						<tr>
							<th class="animal-status--passive"> {{ t("legend.highlight") }}</th> <td>{{ t("legend.status_passive") }}</td>
						</tr>
					</tbody>
				</table>
			</legend>
		</template>
		
	</br-generic-list-base>
</template>

<script>
	
	import { B_REST_Vuetify_GenericList } from "@/bREST/core/implementations/vue";

	export default {
		name: "animalList",
		mixins: B_REST_Vuetify_GenericList.createMixin({
			formComponent: () => import("./AnimalForm.vue"),
			modelName: "Animal",
			fromLoader: {
				apiBaseUrl: "/animals/",
			},
			cols: {
				name:                {fieldNamePaths:"name|calc_hasDueRenewal|calc_hasUnreadNotes", style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT}},
				specie:              {fieldNamePaths:"specie",                                      style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT}},
				status:              {fieldNamePaths:"current_status",                              style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT}},
				calc_hasUnreadNotes: {fieldNamePaths:"calc_hasUnreadNotes",                         style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT}},
				hair_length:         {fieldNamePaths:"hair_length",                                 style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT}},
				tail_length:         {fieldNamePaths:"tail_length",                                 style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT}},
				ear_style:           {fieldNamePaths:"ear_style",                                   style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT}},
			},
			globalActions: {
				...B_REST_Vuetify_GenericList.GlobalAction.defineCommonAction_add({}),
			},
			row: {
				checkbox: {isEnabled:true},
				style: { 
					trClass(model) {
						return "animal-status--" + model.select('current_status').val;
					}
				},
				actions: {
					...B_REST_Vuetify_GenericList.RowAction.defineCommonAction_delete({
						isEnabled(action,model,isCtrlClickOrMiddleClick)
						{
							if (!this.$bREST.userType_isAdmin) { return "perms"; }
							return model.canDelete;
						},
					}),
					...B_REST_Vuetify_GenericList.RowAction.defineCommonAction_edit({}),
				},
			},
			filters: {
				pk:                 {fieldNamePath:"pk", op:B_REST_Vuetify_GenericList.FILTER_OPS.OP_EQ_IN},
				name:               {fieldNamePath:"name"},
				medal_number:       {fieldNamePath:"medal_number", op:B_REST_Vuetify_GenericList.FILTER_OPS.OP_EQ_IN},
				microchip:          {fieldNamePath:"microchip", op:B_REST_Vuetify_GenericList.FILTER_OPS.OP_EQ_IN},
				specie:             {fieldNamePath:"specie"},
				mainBreed:          {fieldNamePath:"main_breed_fk", items:"allBreedList"},
				crossBreed:         {fieldNamePath:"cross_breed_fk", items:"allBreedList"},
				primaryHairColor:   {fieldNamePath:"hair_color_primary_fk", items:"hairColorList"},
				secondaryHairColor: {fieldNamePath:"hair_color_secondary_fk", items:"hairColorList"},
				weight:             {fieldNamePath:"weight"},
				sex:                {fieldNamePath:"sex"},
				hairLength:         {fieldNamePath:"hair_length"},
				tailLength:         {fieldNamePath:"tail_length"},
				earStyle:           {fieldNamePath:"ear_style"},
			},
		}),
		methods: {
			show_warningIcon(model) {
				return model.select('calc_hasDueRenewal').val || model.select('calc_hasUnreadNotes').val || false;
			},
		},
	};
	
</script>

<style scoped>
	
	.br-generic-list:deep(.animal-status--passive),
	.br-generic-list:deep(.animal-status--dead_no_proof) {
		background: yellow;
	}
	
	legend table {
		border-collapse: collapse;
		font-size: 0.9em;
	}
	legend table,
	legend table th,
	legend table td {
		border: 1px solid grey;
	}
	legend table th,
	legend table td {
		padding: 4px;
	}
	
</style>