<template>
	<v-row>
		<br-generic-list-base :derived-component="_self">
			<template #data-table-area>
				<v-card v-for="(loop_model,loop_idx) in modelList.models" :key="loop_idx" class="my-8" :color="isPaid(loop_model) ? null : 'warning'">
					<v-card-text class="pa-0">
						<v-simple-table>
							<tbody>
								<tr> <th>{{ t("cols.pk.shortLabel") }}</th>           <td>{{ loop_model.pk }}</td> </tr>
								<tr> <th>{{ t("cols.animalName.shortLabel") }}</th>   <td>{{ loop_model.select("animal.name").val }}</td> </tr>
								<tr> <th>{{ t("cols.year.shortLabel") }}</th>         <td>{{ loop_model.select("year_valid").val }}</td> </tr>
								<tr> <th>{{ t("cols.medalNumber.shortLabel") }}</th>  <td>{{ loop_model.select("medal_number").val }}</td> </tr>
								<tr> <th>{{ t("cols.is_paid.shortLabel") }}</th>      <td>{{ isPaid(loop_model) ? t("cols.is_paid.yes") : t("cols.is_paid.no") }}</td> </tr>
								<tr> <th>{{ t("cols.status.shortLabel") }}</th>       <td>{{ loop_model.select("status").enum_label }}</td> </tr>
							</tbody>
							<tfoot>
								<tr>
									<th colspan="2" class="text-right py-4">
										<v-btn color="secondary" @click="$bREST.routes_goBlank_moduleForm_pkTag('license',loop_model.pk)">{{ t("viewForm") }}</v-btn>
									</th>
								</tr>
							</tfoot>
						</v-simple-table>
					</v-card-text>
				</v-card>
			</template>
		</br-generic-list-base>
	</v-row>
</template>

<script>
	
	import { B_REST_Vuetify_GenericList } from "@/bREST/core/implementations/vue";
	
	export default {
		name: "licenseList",
		mixins: B_REST_Vuetify_GenericList.createMixin({
			formComponent: () => import("./LicenseForm.vue"),
			modelName: "License",
			fromLoader: {
				apiBaseUrl: "/licenses/",
			},
			cols: {
				animalName:  {fieldNamePaths:"animal.name"},
				year:        {fieldNamePaths:"year_valid"},
				medalNumber: {fieldNamePaths:"medal_number"},
				is_paid:     {fieldNamePaths:"payment_status"},
				status:      {fieldNamePaths:"status"},
					//NOTE: Most of cols data is ignored when we define <template #data-table-area>
			},
			globalActions: {
				//NOTE: We create them via Model_License::createMissingLicenses() instead of manually
			},
		}),
		methods: {
			isPaid(model) { return model.select("payment_status").val!==this.$bREST.consts.licensePaymentStatuses.PAYMENT_STATUS_PENDING; },
		},
	};
	
</script>

<style scoped>
	
	th {
		font-weight: bold;
	}
	
	td {
		text-align: right;
	}
	
	.br-generic-list:deep(.v-toolbar) {
		display: none;
	}
	
	.br-generic-list:deep(.v-data-table) {
		background-color: transparent !important;
	}
	
</style>