
import B_REST_Utils                           from "../B_REST_Utils.js";
import B_REST_App_base                        from "../app/B_REST_App_base.js";
import B_REST_Descriptor                      from "../descriptors/B_REST_Descriptor.js";
import B_REST_Model_ValidationError           from "./B_REST_Model_ValidationError.js";
import B_REST_Model_CustomValidationErrorList from "./B_REST_Model_CustomValidationErrorList.js";
import B_REST_ModelFields                     from "./B_REST_ModelFields.js";
import B_REST_Response                        from "../api/B_REST_Response.js";



export default class B_REST_Model
{
	static get PROPAGATE_PK_FLAG_UNSAVED_CHANGES() { return false; } //For B_REST_ModelField_SubModel_base::_fkFieldVal_propagate(). Should keep false, otherwise we'll save things for no reason when we have no other fields set
	static get SAVE_CLEANUP_DELETIONS()  { return true;  } //If after save we should destroy things marked as toRemove/toDelete
	static get SAVE_SKIP_USELESS_CALLS() { return false; } //Whether or not we want to prevent calling server when toObj() yields no unsaved changes. Not a good idea, if we wanted to inject post data JIT, or if we just want to get a PK
	
	static get TO_LABEL_MAX_LENGTH() { return 50; }
	
	static get CACHED_SHARE_PUT_ALLOCATED_COUNT_NEST() { return false; }
	
	static get API_PATH_VARS_PK_TAG() { return "pkTag"; } //Ex for "/api/clients/{pkTag}". WARNING: If we change this, also change server's Model_base::API_PATH_VARS_PK_TAG
	
	static get API_UID_FIELDNAME()                 { return "_apiUID_";        }
	static get API_EXTRA_DATA_FRONTEND_FIELDNAME() { return "_extraData_ui_";  }
	static get API_EXTRA_DATA_BACKEND_FIELDNAME()  { return "_extraData_api_"; }
	static get API_CAN_DELETE_FIELDNAME()          { return "_canDelete_";     }
	static get API_DIRECTIVE_TAG()                 { return "_apiDirective_";  }
	static get API_DIRECTIVE_REMOVE()              { return "<remove>";        }
	static get API_DIRECTIVE_DELETE()              { return "<delete>";        }
	
	
	static _next_frontendUUID = 1;
	static _cachedShare = {}; //Map of {model name => {models: map of <pkTag> => B_REST_Model instance}}
	
	
	_descriptor                              = null;   //Ptr on a B_REST_Descriptor
	_frontendUUID                            = null;   //Unique ID to help identifying before & after API calls, especially for when the model is used as a sub model and we're waiting to get a PK back
	_fieldData                               = {};     //Map of fieldName => B_REST_ModelFields.x, where we always prepare keys for all fields but leave them undefined, until we care about them
	_hostModelField                          = null;   //Optional B_REST_ModelFields.WithFuncs_WithModels_x, when this is a sub model in some other model's fields
	_canDelete                               = null;   //Either true or translated error msg. Check server's Model_base::toObj() docs
	_toRemove                                = false;  //When this is a sub model (hostModelField!==null), indicates if we wish to unlink it from the parent in the next toObj() / save() call, without deleting it
	_toDelete                                = false;  //Like toRemove, but also deletes it from the DB
	_isReadOnly                              = false;  //When true, all fields become immutable
	_validation_custom_errorList             = null;   //Instance of B_REST_Model_CustomValidationErrorList where the user can toggle errs by himself, ex via validation_custom_xFuncs. NOTE: Parsed via B_REST_Model_ValidationError::getCustomValidationErrors()
	_validation_custom_fastFuncs             = [];     //Check B_REST_Descriptor's docs for the same prop. Further custom validation, except that it's an arr of funcs to run
	_validation_custom_fastThrottle_delay    = null;   //Check B_REST_Descriptor's docs for the same prop. Overrides default delay, if set
	_validation_custom_fastThrottle_lastTime = 0;      //Indicate last time fast func was called
	_validation_custom_asyncFuncs            = [];     //Check B_REST_Descriptor's docs for the same prop. Further custom validation
	_toLabelFunc                             = null;   //Func as (model<B_REST_Model>,reason) that can be called when we call B_REST_Model.toLabel(), to give info about the model instance (ex firstName+lastName). Also in B_REST_Descriptor
	_cache_pk_isSet                          = null;   //NULL = we must recheck all the time. True = we're sure it's ok
	_savePromise                             = null;   //Promise that's only set while we're saving, to prevent saving multiple times in parallel
	_extraData_ui                            = null;   //Extra data we want to carry around in UI, not intended to be read by server
	_extraData_api                           = null;   //As opposed to extraData_ui, will be sent to and also received from server
	_isInCachedShare                         = false;  //If it was added to _cachedShare with cachedShare_put()
	
	
	/*
	Usage ex. Check B_REST_Descriptor's constructor docs first. Note that we can also new one from commonDefs_make():
		const brandDescriptor = new B_REST_Descriptor("Brand", [{name:"pk",type:B_REST_FieldDescriptors.DB.TYPE_INT}], {...});
		const brand_1 = new B_REST_Model(brandDescriptor);
		const brand_2 = new B_REST_Model(brandDescriptor);
		const brand_3 = new B_REST_Model(brandDescriptor);
		const brand_4 = B_REST_Model.commonDefs_make("brand");
		const brand_5 = B_REST_Model.commonDefs_make("brand");
		const brand_6 = B_REST_Model.commonDefs_make("brand");
	*/
	constructor(descriptor)
	{
		B_REST_Utils.instance_isOfClass_assert(B_REST_Descriptor, descriptor);
		
		this._descriptor                  = descriptor;
		this._validation_custom_errorList = new B_REST_Model_CustomValidationErrorList(`models.${this._descriptor.name}.customValidationErrors`);
		this._frontendUUID                = B_REST_Model._next_frontendUUID++;
		
		/*
		IMPORTANT:
			If we plan to make this work with Vue, then we can't just leave _fieldData to {} and add props later as we define wanted fields in _field_getAlloc_byDescriptor()
			So instead, we'll just always add them all but keep their ptr undefined
		*/
		for (const loop_fieldName in this._descriptor.allFields) { this._fieldData[loop_fieldName]=undefined; }
		
		this._field_setDefaultVals();
	}
		/*
		Creates an instance, from a common B_REST_Descriptor (by name)
		Check notes in B_REST_Descriptor::commonDefs_make() for more info
		If we pass an obj as 2nd param, then we can also put data in it in advance
		*/
		static commonDefs_make(name, obj=null)
		{
			const descriptor = B_REST_Descriptor.commonDefs_get(name);
			const model      = new B_REST_Model(descriptor);
			
			if (obj) { model.fromObj(obj); }
			
			return model;
		}
	
	
	
	static _throwEx(msg, details=null) { B_REST_Utils.throwEx(msg,details); }
	       _throwEx(msg, details=null) { B_REST_Utils.throwEx(`${this.debugName}: ${msg}`,details); }
	
	
	
	//GENERAL ACCESSORS
		get descriptor() { return this._descriptor; }
		get debugName()  { return `B_REST_Model<${this._descriptor.name} #${this._pk_debug()}>`; }
		/*
		Variant for B_REST_ModelField_x::debugFieldNamePath(), so we can ultimately ret something like: "<Lead>/mainUser<User>/coords<Coordinate>/address"
		*/
		debugFieldNamePath()
		{
			const modelName = `<${this._descriptor.name}#${this._pk_debug()}>`;
			
			return this._hostModelField ? `${this._hostModelField.debugFieldNamePath()}${modelName}` : modelName;
		}
		
		get frontendUUID() { return this._frontendUUID; }
		
		get hostModelField() { return this._hostModelField; }
		set hostModelField(val)
		{
			B_REST_Utils.instance_isOfClass_assert(B_REST_ModelFields.WithFuncs_WithModels_base, val);
			this._hostModelField = val;
		}
		
		get isReadOnly()    { return this._isReadOnly; }
		set isReadOnly(val) { this._isReadOnly=val;    }
		
		//Even if it's not read only, it also depends about other things in the model
		get isMutable()
		{
			if (this._isReadOnly)                                                    { return false; }
			if (this._isInCachedShare)                                               { return false; }
			if (this._hostModelField && !this._hostModelField.subModel_canBeMutable) { return false; }
			
			return true;
		}
		
		get canDelete() { return this._canDelete; }
		
		get extraData_ui()    { return this._extraData_ui; }
		set extraData_ui(val) { this._extraData_ui=val;    }
		
		get extraData_api()    { return this._extraData_api; }
		set extraData_api(val) { this._extraData_api=val;    }
		
		get isInCachedShare() { return this._isInCachedShare; }
	
	
	
	//TO LABEL RELATED
		get toLabelFunc() { return this._toLabelFunc; }
		set toLabelFunc(val) { this._toLabelFunc=val; }
		/*
		Allows to return a user-friendly name for the instance, ex by concatenating firstName + lastName if set
		If not defined, we'll just take the first set text & non-PK DB field we find, otherwise try with single-field PK
		For custom funcs, the reason param will help if we have multiple ways of outputting method info
		Could even do a multiline output if we wanted
		IMPORTANT: Algo must be equal in server's Model_base::toLabel() & frontend's B_REST_Model::toLabel()
		NOTE:
			Also consider select_firstNonEmptyVal() & select_nonEmptyValsConcatenated()
		*/
		toLabel(reason=null)
		{
			//Check if the descriptor or this model instance defined a special func to use
			try
			{
				if (this._toLabelFunc)
				{
					const label = this._toLabelFunc(this,reason);
					if (label!==null) { return label; }
				}
				if (this._descriptor.model_toLabelFunc)
				{
					const label = this._descriptor.model_toLabelFunc(this,reason);
					if (label!==null) { return label; }
				}
			}
			catch (e) { B_REST_Utils.console_error(`toLabel hook failed, for ${this.debugName}: ${e}`); } //WARNING: Could cause prob to switch to throwEx() - check code below
			
			//Otherwise, check if we have provided expressions to prioritize naming, with toLabel_fieldNamePaths, ex "cieName|user.firstName+user.lastName|coords_address+coords_city+coords_postalCode"
			if (this._descriptor.toLabel_fieldNamePaths)
			{
				const allocatedVals = this.select_firstNonEmptyVal(this._descriptor.toLabel_fieldNamePaths);
				if (allocatedVals!==null) { return allocatedVals; }
			}
			
			//If we have a loc subModelList, then try to use any of its fields
			if (this._descriptor.locTable_has)
			{
				const xLangLocModel = this._localizedField_getXLangLocSubModel(/*checkCreate*/false, /*lang*/null);
				
				if (xLangLocModel)
				{
					const localizedFieldNames = this._descriptor.locTable_fieldNames;
					
					for (const loop_localizedFieldName of localizedFieldNames)
					{
						if (xLangLocModel.select_isUsed(loop_localizedFieldName))
						{
							const loop_localizedModelFieldVal = xLangLocModel.select(loop_localizedFieldName).val ?? null;
							
							if (loop_localizedModelFieldVal!==null) { return B_REST_Utils.string_ellipsis(loop_localizedModelFieldVal,B_REST_Model.TO_LABEL_MAX_LENGTH); }
						}
					}
				}
			}
			
			//If it's used in BrFieldDb.vue::_picker_getModelLabel(), it'll auto append an extra #PK to it, so we don't need to ret anything
			if (reason && reason.indexOf("<br-field-db")===0 && reason.indexOf("@picker")!==-1) { return null; }
			
			return !this.descriptor.isMultiFieldPK && this.pk_isSet ? `#${this.pk}` : null;
		}
		/*
		Helper to get a sub field in a loc table, either in the current lang, or a given lang
		Ex:
			ActivitySector {pk<int>, name<string>, subSectors[<ActivitySector>], loc[<ActivitySector_Loc>]}
			ActivitySector_Loc {activitySector_fk<int>, lang<string>, shortName<string>, longName<string>, desc<string>}
		If we want to get the activity sector's info in a given lang, just do:
			activitySector.localizedField_get("shortName")
			activitySector.localizedField_get("longName")
			activitySector.localizedField_get("desc")
		WARNING: Not a fieldNamePath but fieldName
		*/
		localizedField_get(localizedFieldName, lang=null)
		{
			const xLangLocModel = this._localizedField_getXLangLocSubModel(/*checkCreate*/false, lang);
			if (!xLangLocModel) { return null; }
			
			return xLangLocModel.select(localizedFieldName).val || null;
		}
		/*
		Like the above, but allows to set the field's val
			activitySector.localizedField_set("shortName", "bob",  "fr")
			activitySector.localizedField_set("shortName", "boob", "en")
		*/
		localizedField_set(localizedFieldName, translation, lang=null)
		{
			const xLangLocModel = this._localizedField_getXLangLocSubModel(/*checkCreate*/true, lang);
			
			xLangLocModel.select(localizedFieldName).val = translation;
		}
			_localizedField_getXLangLocSubModel(checkCreate, lang=null)
			{
				if (!this._descriptor.locTable_has) { this._throwEx(`Can only do that on a model w loc table`); }
				
				if (!lang) { lang=B_REST_App_base.instance.locale_lang; }
				
				const locField      = this._select_parseAllocFieldNamePath_nest(this, B_REST_Descriptor.LOC_TABLE_PARENT_FIELDNAME, B_REST_Descriptor.LOC_TABLE_PARENT_FIELDNAME, /*allocate*/true, /*singleSubModelFieldsRetModel*/false,/*throwIfCantNest*/true);
				let   xLangLocModel = locField.get_byFieldNamePathVal(B_REST_Descriptor.LOC_TABLE_LANG_FIELDNAME, lang);
				
				//Create if we don't have a record for that lang
				if (!xLangLocModel && checkCreate)
				{
					xLangLocModel = locField.add();
					xLangLocModel.select(B_REST_Descriptor.LOC_TABLE_LANG_FIELDNAME, lang);
				}
				
				return xLangLocModel;
			}
		
	
	
	//PK RELATED
		/*
		If PK is made of only one field, will return a single val, otherwise map, where each could contain "undefined"
		NOTE: There are no pk setter; use select(<pkName>).val to set it (like that to normalize with multi-field PKs)
		*/
		get pk() { return this._pk_get(/*checkAllocate*/true); }
			//Check docs for pk() getter. If we don't want to checkAllocate and it's not set, will yield a single undefined or a map of {fieldName:undefined}
			_pk_get(checkAllocate)
			{
				if (this._descriptor.isMultiFieldPK)
				{
					const pks = {};
					
					for (const loop_fieldDescriptor of this._descriptor.pks)
					{
						const loop_modelField = this._field_getAlloc_byDescriptor(loop_fieldDescriptor,checkAllocate);
						
						pks[loop_fieldDescriptor.name] = loop_modelField ? loop_modelField.val : undefined;
					}
					
					return pks;
				}
				
				const modelField = this._field_getAlloc_byDescriptor(this._descriptor.pks[0],checkAllocate);
				return modelField ? modelField.val : undefined;
			}
			/*
			Rets a string like:
				?
				new
				123
				undefined|undefined|undefined
				null|fr
				123|fr
			Yield undefined, ex when we've just allocated the field and haven't got time to set the actual val for an existing instance
			WARNING:
				Don't put checkAllocate to true here, because for now, when we alloc a DB field,
				its constructor calls _setVal(UNSET_VAL) to force validation to fail,
				which calcs an err msg that will call this recursively
			*/
			_pk_debug()
			{
				const pk = this._pk_get(/*checkAllocate*/false); //WARNING: Check warning above
				
				if (this._descriptor.isMultiFieldPK)
				{
					//For now, just join all vals; if we get undefineds and nulls, their toString will convert them to text
					
					const toStringedArr = [];
					for (const loop_fieldDescriptor of this._descriptor.pks)
					{
						toStringedArr.push( `${pk[loop_fieldDescriptor.name]}` );
					}
					return toStringedArr.join("|");
				}
				else if (pk===undefined) { return "?";   }
				else if (pk===null)      { return "new"; }
				else                     { return pk;    }
			}
		//Can only do that in single mode, for now
		set pk(val)
		{
			if (this._descriptor.isMultiFieldPK) { this._throwEx(`Can only use the pk setter shortcut in single PK field models`); }
			
			this._field_getAlloc_byDescriptor(this._descriptor.pks[0],/*allocate*/true).val = val;
		}
		//Ex 123 or "123-fr" for multi-field PK. For simplicity, doesn't validate if PK is set, but we could end up with undefineds
		get pk_tag() { return this._descriptor.pkToTag_vals(this.pk); }
		//If it contains multiple fields, then all must be set
		get pk_isSet()
		{
			if (this._cache_pk_isSet) { return true; }
			
			let allSet = null;
			
			if (this._descriptor.isMultiFieldPK)
			{
				allSet = true;
				
				for (const loop_fieldDescriptor of this._descriptor.pks)
				{
					allSet &= this._field_getAlloc_byDescriptor(loop_fieldDescriptor,/*allocate*/true).isSet;
				}
			}
			else { allSet = this._field_getAlloc_byDescriptor(this._descriptor.pks[0],/*allocate*/true).isSet; }
			
			if (allSet) { this._cache_pk_isSet=true; }
			
			return allSet;
		}
		get isNew()
		{
			if (!this._descriptor.isAutoInc) { this._throwEx(`For now, can't tell if a non AUTO_INC record is new / existing. Check how it's done in server`); }
				//However, we could tell by checking if PK fields have unsaved changes (ex have just been set to {fk:123,lang:fr})
			
			return !this.pk_isSet;
		}
		//Called in B_REST_ModelFields.DB val setter, if it's a PK field (no matter AUTO_INC or not, or isMultiFieldPK or not)
		pk_propagateSingleSetValToSubModels()
		{
			//Only for single field PKs
			if (this._descriptor.isMultiFieldPK) { return; }
			
			const pkVal = this.pk;
			if (pkVal===undefined) { this._throw(`PK not set`); } //Maybe NULL is a good val in rare cases...
			
			//NOTE: For sub models, don't allocate for no reason, otherwise they'll always get sent when we do toObj()
			{
				const subModelFields = this._descriptor.subModelFields;
				for (const loop_fieldName in subModelFields)
				{
					const loop_modelOrModelListOrNULL = this._field_getAlloc_byDescriptor(subModelFields[loop_fieldName],/*allocate*/false);
					if (loop_modelOrModelListOrNULL) { loop_modelOrModelListOrNULL.fkFieldVal=pkVal; }
				}
				
				const subModelListFields = this._descriptor.subModelListFields;
				for (const loop_fieldName in subModelListFields)
				{
					const loop_modelOrModelListOrNULL = this._field_getAlloc_byDescriptor(subModelListFields[loop_fieldName],/*allocate*/false);
					if (loop_modelOrModelListOrNULL) { loop_modelOrModelListOrNULL.fkFieldVal=pkVal; }
				}
			}
		}
	
	
	
	//FIELDS RELATED
		_field_setDefaultVals()
		{
			/*
			NOTE: For now, we're only doing for DB fields, and we're not nesting down req sub models w optional fields. Maybe we should... If we change behavior, also do so in backend
			WARNING: In backend, optional val is defined in FieldDescriptor_base, whereas in frontend it's only in the DB der
			*/
			
			for (const loop_fieldName in this._descriptor.dbFields)
			{
				const loop_fieldDescriptor = this._descriptor.dbFields[loop_fieldName];
				
				if (loop_fieldDescriptor.optionalVal_has)
				{
					const loop_field = this._select_parseAllocFieldNamePath_nest(this,loop_fieldName,loop_fieldName,/*allocate*/true,/*singleSubModelFieldsRetModel*/false,/*throwIfCantNest*/true);
					loop_field.val = loop_fieldDescriptor.optionalVal;
				}
			}
		}
		_field_allocatedCount(nestInSubModels)
		{
			if (nestInSubModels) { this._throwEx(`Nesting in sub models not yet implemented`); } //And maybe not useful, if we've got only 1 field set in main model and the rest is in a subModelList
			
			let count = 0;
			
			for (const loop_fieldName in this._fieldData)
			{
				if (this._fieldData[loop_fieldName]!==undefined) { count++; }
			}
			
			return count;
		}
		//Get the B_REST_ModelFields.x behind the field. Optionally allocates it JIT
		_field_getAlloc_byDescriptor(fieldDescriptor, allocate)
		{
			const fieldName = fieldDescriptor.name;
			
			//If already instantiated
			if (this._fieldData[fieldName]!==undefined) { return this._fieldData[fieldName]; }
			
			//Otherwise instantiate it, and if it was a sub model/list, check if we can also propagate PK val
			if (allocate)
			{
				const modelField = fieldDescriptor.factory_modelField(this);
				if (modelField instanceof B_REST_ModelFields.SubModel_base && this.pk_isSet) { modelField.fkFieldVal=this.pk; } //Note that it's impossible to have sub models in a multi-field PK model
				return this._fieldData[fieldName] = modelField;
			}
			
			return null;
		}
		/*
		Allocates and returns a ptr to the specified field; in most case its B_REST_ModelFields.x instance, and in some other case a ptr to a sub B_REST_Model instance:
			B_REST_FieldDescriptor_DB:             Rets the B_REST_ModelFields.DB instance, so we can then do stuff like .val to get/set val
			B_REST_FieldDescriptor_ModelLookupRef: Rets the B_REST_ModelFields.ModelLookupRef's bound B_REST_Model instance
			B_REST_FieldDescriptor_SubModel:       Rets the B_REST_ModelFields.SubModel's bound B_REST_Model instance
			B_REST_FieldDescriptor_SubModelList:   Rets the B_REST_ModelFields.SubModelList instance, so we can do funcs on it directly like checking / adding sub models
			B_REST_FieldDescriptor_Other:          Rets the B_REST_ModelFields.Other instance
			B_REST_FieldDescriptor_File:           Rets the B_REST_ModelFields.File instance, so we can do operations on files
		We can access sub model fields by using dot notation. Check below or B_REST_Utils.parseFieldNamePath() & server side Model_base::_field_parseFieldNamePath() for docs
		Usage ex:
			Def:
				firstName -> DB
				lastName  -> DB
				coords    -> Sub model with address, city...
				contacts  -> Sub model list of firstName, lastName, coords...
			Then we can do:
				//Get / set ex for DB fields
				model.select("firstName").val = "Bob"
				const firstName = model.select("lastName").val;
				
				//2 ways of accessing sub model stuff
				model.select("coords").select("address").val = "2714 ...";
				model.select("coords.address").val           = "2714 ...";
				
				//3 ways of accessing sub model list stuff
				model.select("contacts").subModels[5].select("firstName").val = "Bob";
				model.select("contacts").get_byIdx(5).select("firstName").val= "Bob";
				model.select("contacts[5].firstName").val                     = "Bob";
				
				//Nesting down even further
				model.select("contacts").subModels[5].select("coords").select("address").val = "2714 ...";
				model.select("contacts").get_byIdx(5).select("coords.address").val           = "2714 ...";
				model.select("contacts[5].coords.address").val                               = "2714 ...";
		Validation:
			-Model's validation methods will only take into account fields that we're using / referring to, even if they're isRequired / !isNullable and not yet referred to
			-To manually specify fields we want to "add" to those that counts in the overall model being valid or not, use select_some() or select_all()
		WARNING:
			For TYPE_ARR & TYPE_MULTILINGUAL_STRING:
				Say we wanted to get arr val at pos 2, or loc's FR lang key val, don't set that inside the field name path:
					NOT OK:
						select("someField[2]")
						select("someField.fr")
					OK:
						select("someField").val[2]
						select("someField").val_fr
		*/
		select(fieldNamePath,singleSubModelFieldsRetModel=true) //singleSubModelFieldsRetModel: As opposed to ret the -field-. Also, w allocate=true, will allocate the -field- but not the model in the field
		{
			try
			{
				return this._select_parseAllocFieldNamePath_nest(this,fieldNamePath,fieldNamePath,/*allocate*/true,singleSubModelFieldsRetModel,/*throwIfCantNest*/true);
			}
			catch (e)
			{
				this._throwEx(`Got err while trying to select("${fieldNamePath}"): ${e}`);
			}
		}
			_select_parseAllocFieldNamePath_nest(modelLvl, self_fieldNamePath, original_fieldNamePath, allocate, singleSubModelFieldsRetModel, throwIfCantNest)
			{
				const {self_fieldName, atIdx, target_fieldNameOrExpr} = B_REST_Utils.parseFieldNamePath(self_fieldNamePath);
				
				//First find our field
				const self_fieldDescriptor = modelLvl._descriptor.allFields_find(self_fieldName); //Might throw
				const self_field           = modelLvl._field_getAlloc_byDescriptor(self_fieldDescriptor, allocate);
				
				//If we didn't want to allocate not yet used stuff, then we can't do nothing more
				if (!self_field) { return null; }
				
				//If we expect to go down in one of our B_REST_ModelFields.SubModelList field, and either return it or a sub field
				if (atIdx!==null)
				{
					//First make sure the field was a sub model list, otherwise we can't navigate sub models
					if (!(self_field instanceof B_REST_ModelFields.SubModelList))
					{
						this._throwEx(`[${atIdx}] field name path must point on a sub model list field, for "${original_fieldNamePath}"`);
					}
					
					//Try to get the sub model at X pos. Might throw if not set yet
					const sub_modelLvl = self_field.get_byIdx(atIdx);
					
					if (target_fieldNameOrExpr) { return this._select_parseAllocFieldNamePath_nest(sub_modelLvl,target_fieldNameOrExpr,original_fieldNamePath,allocate,singleSubModelFieldsRetModel,throwIfCantNest); }
					return sub_modelLvl;
				}
				//If we want to go through a sub model or lookup and get an inner field
				else if (target_fieldNameOrExpr)
				{
					//First make sure the field was a sub model or lookup, otherwise we can't navigate sub models
					if (!(self_field instanceof B_REST_ModelFields.SubModel || self_field instanceof B_REST_ModelFields.ModelLookupRef))
					{
						this._throwEx(`To have a nested field name path without [idx], must go through a sub model (!asList) or model lookup ref, for "${original_fieldNamePath}"`);
					}
					
					//This shouldn't happen for B_REST_ModelFields.SubModel, but maybe for B_REST_ModelFields.ModelLookupRef
					if (!self_field.model_has)
					{
						if (throwIfCantNest) { this._throwEx(`Can't travel in sub model, because it's not yet allocated, for "${original_fieldNamePath}". If using in Vue like <br-field-db :model="model" field="someNullLookup.someField" />, then should add a v-if="!!model.select("someNullLookup").model"`); }
						else                 { return null; }
							/*
							NOTE: Used to do the following instead:
								B_REST_Utils.console_warn(`${this.debugName}: Can't travel in sub model, because it's not yet allocated, for "${original_fieldNamePath}"`);
								return null; //Not sure if it makes sense to ret NULL, or if we should alloc it JIT (and depending on if it's shared or not..)
							*/
					}
					
					return this._select_parseAllocFieldNamePath_nest(self_field.model, target_fieldNameOrExpr, original_fieldNamePath, allocate, singleSubModelFieldsRetModel, throwIfCantNest);
				}
				//Else final cases
				else if (self_field instanceof B_REST_ModelFields.DB)             { return self_field; }
				else if (self_field instanceof B_REST_ModelFields.Other)          { return self_field; }
				else if (self_field instanceof B_REST_ModelFields.File)           { return self_field; }
				else if (self_field instanceof B_REST_ModelFields.SubModelList)   { return self_field; }
				else if (self_field instanceof B_REST_ModelFields.SubModel)       { return singleSubModelFieldsRetModel ? self_field.model : self_field; } //Should always be set
				else if (self_field instanceof B_REST_ModelFields.ModelLookupRef) { return singleSubModelFieldsRetModel ? self_field.model : self_field; } //Not sure if it'd always be set
				else { this._throwEx(`Unknown kind of field received`,self_field); }
			}
		
		/*
		Allocates the specified fields so they're taken into account in validation methods
		Uses the same dot notation as in select()
		Usage ex:
			select_some("firstName|contacts[5].coords(address|email)");
		*/
		select_some(pipedFieldNamePaths)
		{
			const fieldNamePaths = B_REST_Utils.splitPipedFieldNamePaths(pipedFieldNamePaths);
			
			for (const loop_fieldNamePath of fieldNamePaths) { this.select(loop_fieldNamePath); }
		}
			/*
			Same as select_some(), but for all fields and their sub fields
			WARNING:
				This should be used right before we want to validate the model, not at the beginning, in case we have sub model list and we add new sub models -after-,
				since these won't know that all of their fields are supposed to be required too
			*/
			select_all()
			{
				const allFields = this._descriptor.allFields;
				
				for (const loop_fieldName in allFields)
				{
					const loop_fieldDescriptor = allFields[loop_fieldName];
					const loop_field           = this._field_getAlloc_byDescriptor(loop_fieldDescriptor, /*allocate*/true);
					
					//Check if we need to nest
					{
						if      (loop_field instanceof B_REST_ModelFields.SubModel)       { loop_field.model.select_all(); } //Should always be set
						else if (loop_field instanceof B_REST_ModelFields.SubModelList)   { loop_field.select_all();       }
						else if (loop_field instanceof B_REST_ModelFields.ModelLookupRef) { loop_field.model.select_all(); } //Not sure if it'd always be set
					}
				}
			}
		/*
		Just indicates if the specified field was previously reached via select_x() (or loaded)
		Throws though, if we try to access something like "subModel[3].field" when idx is out of range
		*/
		select_isUsed(fieldNamePath)
		{
			return !!this._select_parseAllocFieldNamePath_nest(this, fieldNamePath, fieldNamePath, /*allocate*/false, /*singleSubModelFieldsRetModel*/false, /*throwIfCantNest*/false);
		}
		//Especially for when fromObj() rets a NULL for a sub model, cascade wipe everything, without destroying selectors (except if we have things like "someSubModelList[123].abc")
		fields_nullify_all()
		{
			for (const loop_fieldName in this._fieldData)
			{
				if (this._fieldData[loop_fieldName]) { this._fieldData[loop_fieldName].nullify(); }
			}
		}
		//Equivalent of doing B_REST_ModelField_x::label. NOTE: We can't just do select(<fieldNamePath>).label, because if it's a lookup or sub model, we ret the model instead of the field
		select_label(fieldNamePath)      { return this._descriptor.field_loc_label(fieldNamePath);      }
		select_shortLabel(fieldNamePath) { return this._descriptor.field_loc_shortLabel(fieldNamePath); }
		/*
		Say we want to ret the name of a user, but we don't know if they set their first name, or last name, or email etc
		Instead of doing something like:
			const name = entity.select("user.firstName").val || entity.select("user.lastName").val || entity.select("user.recoveryEmail").val || entity.select("user.userName").val || "???";
		We can do:
			const name = user.select_firstNonEmptyVal("user.firstName|user.lastName|user.recoveryEmail|user.userName");
		Advanced case combining fields, if all are filled at the same time:
			const name = user.select_firstNonEmptyVal("user.firstName+user.lastName|user.coords_address+user.coords_city+user.coords_postalCode");
		If some fields are sub models directly (not specifying their sub DB fields), then it'll user their toLabel()
		Else rets NULL
		NOTE: Doesn't support the "()" notation like "user(firstName|lastName)|somethingElse", because it wouldn't know what to do about the "+" yet
		IMPORTANT: Algo must be equal in server's Model_base::field_getMultiple_firstNonEmptyVal() & frontend's B_REST_Model::select_firstNonEmptyVal()
		*/
		select_firstNonEmptyVal(fieldNamePaths, subModels_toLabelReason=null)
		{
			for (const loop_expression of fieldNamePaths.split("|")) //NOTE: Check func comments; can't use B_REST_Utils::splitPipedFieldNamePaths() here
			{
				const loop_reqFieldNamePaths = loop_expression.split("+");
				const loop_vals              = [];
				
				//Check if all req fields are set
				for (const loop_reqFieldNamePath of loop_reqFieldNamePaths)
				{
					if (!this.select_isUsed(loop_reqFieldNamePath)) { break; }
					
					const loop_x   = this.select(loop_reqFieldNamePath, /*singleSubModelFieldsRetModel*/true);
					let   loop_val = null;
					
					if      (loop_x instanceof B_REST_ModelFields.DB)           { loop_val=loop_x.valToText();                                                  }
					else if (loop_x instanceof B_REST_ModelFields.SubModelList) { this._throwEx(`Not supporting "${loop_reqFieldNamePath}"'s field type here`); }
					else if (loop_x instanceof B_REST_Model)                    { loop_val=loop_x.toLabel(subModels_toLabelReason);                             } //For B_REST_ModelFields.WithFuncs_WithModels_base that aren't sub model list
					//For Other & File
					else { this._throwEx(`Not supporting "${loop_reqFieldNamePath}"'s field type here`); }
					
					if (loop_val===null || loop_val==="") { break; }
					loop_vals.push(loop_val);
				}
				
				//If we've got the same nb of vals than req fields, it means we didn't break the above loop
				if (loop_reqFieldNamePaths.length===loop_vals.length) { return loop_vals.join(" "); }
				//Else keep on looking
			}
			
			return null;
		}
		/*
		A bit like select_firstNonEmptyVal(), but concats all non-empty fields with given separator
		If no field has useful data, rets NULL
		We can replace empty vals by something else ex "?" by setting emptyValReplacement to something else than NULL
			Ex:
				select_nonEmptyValsConcatenated("firstName|lastName", "?")
					-> "Some first name ?" or "? Some last name"
			NOTE: Eventually, we might want to change this to also allow objects, so we could do something advanced like
				select_nonEmptyValsConcatenated("firstName|lastName", {firstName:"No first name",lastName:"No last name"})
				but it'd have to work with nested field name paths and stuff like "a.b(c|d)"
		NOTE:
			Intended to be used w DB fields only, but ok to use w sub models etc as long as their toLabel() returns something useful
			Here we can use the "()" notation like the following:
					select_nonEmptyValsConcatenated("user(firstName|lastName|coords_address|coords_city|coords_postalCode)|somethingElse");
				Instead of:
					select_nonEmptyValsConcatenated("user.firstName|user.lastName|user.coords_address|user.coords_city|user.coords_postalCode");
		IMPORTANT: Algo must be equal in server's Model_base::field_getMultiple_nonEmptyValsConcatenated() & frontend's B_REST_Model::select_nonEmptyValsConcatenated()
		WARNING:
			If specified field(s) isn't being used at all / not loaded, we'll consider it's empty
			Contrary to select_firstNonEmptyVal(), doesn't allow the "<fieldName>+<fieldName>" notation like such, because that one already concats:
				const name = user.select_nonEmptyValsConcatenated("user.firstName+user.lastName|user.coords_address+user.coords_city+user.coords_postalCode");
			Should be like the following, though it'll yield diff result:
				const name = user.select_nonEmptyValsConcatenated("user.firstName|user.lastName|user.coords_address|user.coords_city|user.coords_postalCode");
		*/
		select_nonEmptyValsConcatenated(fieldNamePaths, separator=' ', emptyValReplacement=null, subModels_toLabelReason=null)
		{
			const vals = [];
			
			fieldNamePaths = B_REST_Utils.splitPipedFieldNamePaths(fieldNamePaths);
			for (const loop_fieldNamePath of fieldNamePaths)
			{
				let loop_val = null;
				
				if (this.select_isUsed(loop_fieldNamePath))
				{
					const loop_x = this.select(loop_fieldNamePath, /*singleSubModelFieldsRetModel*/true);
					
					if      (loop_x instanceof B_REST_ModelFields.DB)                        { loop_val=loop_x.valToText();                                                  }
					else if (loop_x instanceof B_REST_ModelFields.SubModelList)              { this._throwEx(`Not supporting "${loop_reqFieldNamePath}"'s field type here`); }
					else if (loop_x instanceof B_REST_ModelFields.WithFuncs_WithModels_base) { loop_val=loop_x.toLabel(subModels_toLabelReason);                             }
					//For others & files, skip
					else { this._throwEx(`Not supporting "${loop_reqFieldNamePath}"'s field type here`); }
					
					if (loop_val==="") { loop_val=null; }
				}
				
				if      (loop_val!==null)            { vals.push(loop_val);            }
				else if (emptyValReplacement!==null) { vals.push(emptyValReplacement); }
			}
			
			return vals.length>0 ? vals.join(separator) : null;
		}
	
	
	
	//VALIDATION RELATED
		/*
		GENERAL NOTES:
			By default, only fields referred via select(<fieldName>) will be taken into account, unless we call select_some() or select_all() to add more
			Note that this is independent of fields with unsaved changes and userTouch_x() methods
			Custom validation:
				In B_REST_Descriptor, we have props for defining extra validation stubs that are always the same
				In B_REST_Model, we can define them again, but for things that'd differ between instances of the same descriptor
				The funcs are as function(model) {}
				The fast one is called each time something is changed, while the async one must be called manually
				The goal of these is to add to validation_custom_errorList on models or fields (ex oldPwd/newPwd or email/confirmEmail must match...)
				To check if fields are being "used", do model.select_isUsed(<fieldNamePath>)
			WARNING:
				If we want to make sure we validate sub model list stuff, then check the warning in select_all() docs, about adding stuff later
				In short, we might need to consider calling select_all() right before validating
		*/
			get validation_custom_fastFuncs()     { return this._validation_custom_fastFuncs;     }
			validation_custom_fastFuncs_add(func) { this._validation_custom_fastFuncs.push(func); }
			
			get validation_custom_fastThrottle_delay()     { return this._validation_custom_fastThrottle_delay; }
			set validation_custom_fastThrottle_delay(func) { this._validation_custom_fastThrottle_delay=func;   }
			
			get validation_custom_asyncFuncs()     { return this._validation_custom_asyncFuncs;     }
			validation_custom_asyncFuncs_add(func) { this._validation_custom_asyncFuncs.push(func); }
			
			//Rets the instance of B_REST_Model_CustomValidationErrorList in the model
			get validation_custom_errorList() { return this._validation_custom_errorList; }
			
			/*
			This is meant to be called especially by B_REST_ModelFields.DB instances when their val changes
			We might decide to recalc nothing, fast things and/or async stuff too
			Recurse up/down is about if we want to travel host model field's parentModel up, and any subModels down
			Using validation_custom_fastThrottle_delay, we might decide it's too CPU intensive to run that and do it later. However, if we must recurse, we'll still do that anyways
			*/
			validation_custom_recalc_fast(recurseUp=false, recurseDown=true)
			{
				if (this._validation_custom_recalc_fast_isRunning) {return;} //Prevent endless loops
				
				try
				{
					this._validation_custom_recalc_fast_isRunning = true;
					
					if (recurseDown)
					{
						for (const loop_fieldName in this._fieldData)
						{
							const loop_field = this._fieldData[loop_fieldName];
							if (loop_field===undefined) { continue; }
							
							if (loop_field instanceof B_REST_ModelFields.WithFuncs_WithModels_base) { loop_field.validation_custom_recalc_fast(/*recurseUp*/false, /*recurseDown*/true); }
						}
					}
					
					//Do we even have custom fast validation ourselves ?
					if (this._validation_custom_fastFuncs.length>0 || this._descriptor.validation_custom_fastFuncs.length>0)
					{
						let canRun = true;
						
						//Check if we might have to postpone
						{
							const delay = this._validation_custom_fastThrottle_delay!==null ? this._validation_custom_fastThrottle_delay : this._descriptor.validation_custom_fastThrottle_delay;
							if (delay)
							{
								const now = B_REST_Utils.dt_u(B_REST_Utils.dt_now());
								
								if (now < this._validation_custom_fastThrottle_lastTime+delay) { canRun = false; }
								else { this._validation_custom_fastThrottle_lastTime = now; }
							}
						}
						
						if (canRun)
						{
							try
							{
								for (const loop_validation_custom_fastFunc of this._descriptor.validation_custom_fastFuncs) { loop_validation_custom_fastFunc(this); }
								for (const loop_validation_custom_fastFunc of this._validation_custom_fastFuncs)            { loop_validation_custom_fastFunc(this); }
							}
							catch (e) { B_REST_Utils.console_error(`validation_custom_fastFunc_x hook failed, for ${this.debugName}: ${e}`); }
						}
					}
					
					if (recurseUp && this._hostModelField?.parentModel)
					{
						this._hostModelField.parentModel.validation_custom_recalc_fast(/*recurseUp*/true,/*recurseDown*/false);
					}
				}
				catch (e) { B_REST_Utils.console_error(`validation_custom_recalc_fast failed, for ${this.debugName}: ${e}`); }
				
				this._validation_custom_recalc_fast_isRunning = false;
			}
				_validation_custom_recalc_fast_isRunning = false; //Avoid endless loops
			//Like validation_custom_recalc_fast(), but does it now and includes async calls too. Doesn't return if all is well
			async validation_custom_recalc_wait(recurseUp=false, recurseDown=true)
			{
				if (this._validation_custom_recalc_wait_isRunning) {return;} //Prevent endless loops
				
				let errToThrow = null;
				
				try
				{
					this._validation_custom_recalc_wait_isRunning = true;
					
					if (recurseDown)
					{
						for (const loop_fieldName in this._fieldData)
						{
							const loop_field = this._fieldData[loop_fieldName];
							if (loop_field===undefined) { continue; }
							
							if (loop_field instanceof B_REST_ModelFields.WithFuncs_WithModels_base) { await loop_field.validation_custom_recalc_wait(/*recurseUp*/false, /*recurseDown*/true); }
						}
					}
					
					//For ours, here we don't throttle anymore
					try
					{
						for (const loop_validation_custom_fastFunc of this._descriptor.validation_custom_fastFuncs) { loop_validation_custom_fastFunc(this); }
						for (const loop_validation_custom_fastFunc of this._validation_custom_fastFuncs)            { loop_validation_custom_fastFunc(this); }
						
						for (const loop_validation_custom_asyncFunc of this._descriptor.validation_custom_asyncFuncs) { await loop_validation_custom_asyncFunc(this); }
						for (const loop_validation_custom_asyncFunc of this._validation_custom_asyncFuncs)            { await loop_validation_custom_asyncFunc(this); }
					}
					catch (e) { errToThrow=`validation_custom_xFunc hook failed, for ${this.debugName}: ${e}`; }
					
					//NOTE: Even if we caught an err above, we should still go on w the following validations anyways, to get as much useful info we can on other fields (though if it's a server prob, all async tasks are likely to fail...)
					if (recurseUp && this._hostModelField) { await this._hostModelField.validation_custom_recalc_wait(/*recurseUp*/true,/*recurseDown*/false); }
				}
				catch (e) { errToThrow=`validation_custom_recalc_wait failed, for ${this.debugName}: ${e}`; }
				
				this._validation_custom_recalc_wait_isRunning = false;
				
				//Do this after unflagging _validation_custom_recalc_wait_isRunning, otherwise we won't be able to revalidate later
				if (errToThrow) { this._throwEx(errToThrow); }
			}
				_validation_custom_recalc_wait_isRunning = false; //Avoid endless loops
			
			//Whether we have no error msgs nowhere at all
			get validation_isValid()          { return this.validation_getErrors(/*detailed*/false,/*onlyOne*/true,/*includeAsyncCustomErrors*/true).length ===0; }
			get validation_isValid_fastOnly() { return this.validation_getErrors(/*detailed*/false,/*onlyOne*/true,/*includeAsyncCustomErrors*/false).length===0; }
				//Always ret an arr, either of B_REST_Model_ValidationError instances or just the err msgs
				validation_getErrors(detailed=true, onlyOne=false, includeAsyncCustomErrors=true)
				{
					//First start w custom errors in validation_custom_errorList
					const errors = B_REST_Model_ValidationError.getCustomValidationErrors(this, detailed,onlyOne,includeAsyncCustomErrors);
					
					//Check if we have to ignore AUTO_INC pk fields
					const pkFieldNameToIgnore = this._descriptor.isAutoInc ? this._descriptor.pks[0].name : null;
					
					//Only for "used" / those we did select() on
					for (const loop_fieldName in this._fieldData)
					{
						if (loop_fieldName===pkFieldNameToIgnore) { continue; }
						
						const loop_field = this._fieldData[loop_fieldName];
						if (loop_field===undefined) { continue; }
						
						const loop_field_errors = loop_field.validation_getErrors(detailed,onlyOne,includeAsyncCustomErrors);
						
						if (loop_field_errors.length>0)
						{
							errors.push(...loop_field_errors); //ES6 to concat faster than Array.concat()
							if (onlyOne) { break; }
						}
					}
					
					return errors;
				}
	
	
	
	//UNSAVED CHANGES RELATED
		/*
		GENERAL NOTES:
			Only check for used fields with select_x()
			It's possible that unsavedChanges_has is true but this rets an empty arr, if toRemoveOrDelete
			Flagged everytime we do mods in fields, no matter by user or programmatically.
				In contrast, userTouch_x() funcs are to be triggered by the user manually.
		*/
		//Rets an arr of B_REST_ModelFields.x with mods.
		unsavedChanges_getFields() { return this._unsavedChanges_getFields(/*onlyOne*/false); }
		//If any field has unsaved mods, or if this model is marked as to del (same logic for sub models)
		get unsavedChanges_has()
		{
			if (this.toRemoveOrDelete) { return true; }
			
			return this._unsavedChanges_getFields(/*onlyOne*/true).length>0;
		}
			//Alyways ran arr of B_REST_ModelFields.x
			_unsavedChanges_getFields(onlyOne)
			{
				const fields = [];
				
				for (const loop_fieldName in this._fieldData)
				{
					const loop_field = this._fieldData[loop_fieldName];
					if (loop_field===undefined) { continue; }
					
					if (loop_field.unsavedChanges_has)
					{
						fields.push(loop_field);
						if (onlyOne) { break; }
					}
				}
				
				return fields;
			}
		/*
		Recursively unflags all fields and sub models, without reverting data (nor flipping back sub models toRemoveOrDelete state; can rather destroy them with cleanupDeletions param)
		Intended to use after successful API calls
		To unflag a specific one, use select(<fieldNamePath>).unsavedChanges_unflag()
		Optionally cleans up B_REST_ModelFields.SubModelList sub models that were in toRemoveOrDelete state
		WARNING:
			After an API call, if we had new PKs to set (main model + sub models), we should call this after having set them,
			since setting fields triggers new unsaved changes flags.
		*/
		unsavedChanges_unflagAllFields(options)
		{
			options = B_REST_Utils.object_hasValidStruct_assert(options, {
				cleanupDeletions: {accept:[Boolean], required:true},
				filesOnly:        {accept:[Boolean], default:false}, //A bit like toObj()
			}, "B_REST_Model::unsavedChanges_unflagAllFields()");
			
			for (const loop_fieldName in this._fieldData)
			{
				const loop_field = this._fieldData[loop_fieldName];
				if (loop_field===undefined) { continue; }
				
				loop_field.unsavedChanges_unflag(options);
			}
		}
		//Forces that next time we do toObj({onlyWithUnsavedChanges:true}) / save(), we include all fields that ever got selected
		unsavedChanges_flagAllFields()
		{
			for (const loop_fieldName in this._fieldData)
			{
				const loop_field = this._fieldData[loop_fieldName];
				if (loop_field===undefined) { continue; }
				
				loop_field.unsavedChanges_flag();
			}
		}
	
	
	
	//USER TOUCH RELATED
		/*
		GENERAL NOTES:
			Contrary to unsavedChanges_x(), user touches don't get automatically flagged when we modify fields, as programmatic mods shouldn't count
				Also, things flagged as toRemoveOrDelete have nothing to do with what's touched in UIs
			Only check for used fields with select_x()
		*/
		//Rets an arr of B_REST_ModelFields.x with touches
		userTouch_getFields() { return this._userTouch_getFields(/*onlyOne*/false);         }
		get userTouch_has()   { return this._userTouch_getFields(/*onlyOne*/true).length>0; }
			//Always rets an arr of B_REST_ModelFields.x
			_userTouch_getFields(onlyOne)
			{
				const fields = [];
				
				for (const loop_fieldName in this._fieldData)
				{
					const loop_field = this._fieldData[loop_fieldName];
					if (loop_field===undefined) { continue; }
					
					if (loop_field.userTouch_has)
					{
						fields.push(loop_field);
						if (onlyOne) { break; }
					}
				}
				
				return fields;
			}
		/*
		Recursively toggle all fields and sub models
		To target a specific one, use select(<fieldNamePath>).userTouch_toggle(<bool>)
		*/
		userTouch_toggleAllFields(touched)
		{
			for (const loop_fieldName in this._fieldData)
			{
				const loop_field = this._fieldData[loop_fieldName];
				if (loop_field===undefined) { continue; }
				
				loop_field.userTouch_toggle(touched)
			}
		}
	
	
	
	//FROM / TO OBJ RELATED
		/*
		Uses field accessors, so:
			-If fields were not yet "used", will allocate them
			-Will update validation errors, so it's "ok" to pass somewhat invalid data
			-Will update related PKs & FKs
			-Can throw on mutability
		Doesn't unflag unsaved changes, so we must then manually call unsavedChanges_unflagAllFields() or select(<fieldNamePath>).unsavedChanges_unflag()
		Works with API directives too. Check backend's Model_base::field_set() docs for more info
		Check docs in toRemove() & toDelete() for when sub-models could get auto destroyed from parent models
		We handle DB fields at last, to help with lookups, and PKs even later, to prevent hell with setAllOnce and isMutable
		If we get NULL, then we'll wipe everything inside
		WARNING:
			We could have to call validation_custom_recalc_fast() after doing this
			Ex when we load an existing model, first constructor will call _field_setDefaultVals(), where fields' _setVal() will cause any _validation_custom_fastFuncs to be triggered,
				and so it could falsely tell that a field is invalid, because it wasn't fixed yet, and it wouldn't refix custom validation state at the end
		*/
		fromObj(obj, skipIllegalChanges=false)
		{
			if (obj===null) { this.fields_nullify_all(); return; }
			
			try
			{
				const pksToSetLast = []; //Arr of {field,val}
				const dbsToSetLast = []; //Arr of {field,val}; non-pks
				
				for (const loop_fieldName in obj)
				{
					if (loop_fieldName===B_REST_Model.API_UID_FIELDNAME)                 {                                          continue; }
					if (loop_fieldName===B_REST_Model.API_EXTRA_DATA_BACKEND_FIELDNAME)  { this._extraData_api=obj[loop_fieldName]; continue; }
					if (loop_fieldName===B_REST_Model.API_EXTRA_DATA_FRONTEND_FIELDNAME) { this._extraData_ui =obj[loop_fieldName]; continue; } //Ex if fromObj() was called for an API response, it's ok that server tells us what to put here
					if (loop_fieldName===B_REST_Model.API_CAN_DELETE_FIELDNAME)          { this._canDelete    =obj[loop_fieldName]; continue; } //Check server's Model_base::toObj() docs
					
					const loop_field = this._select_parseAllocFieldNamePath_nest(this,loop_fieldName,loop_fieldName,/*allocate*/true,/*singleSubModelFieldsRetModel*/false,/*throwIfCantNest*/true);
					const loop_val   = obj[loop_fieldName];
					
					//Check method docs for why we do this
					if (loop_field instanceof B_REST_ModelFields.DB)
					{
						if (loop_field.fieldDescriptor.isPKField) { pksToSetLast.push({field:loop_field, val:loop_val}); }
						else                                      { dbsToSetLast.push({field:loop_field, val:loop_val}); }
					}
					else { loop_field.fromObj(loop_val,skipIllegalChanges); }
				}
				
				//Do the rest
				for (const loop_fieldInfo of dbsToSetLast) { loop_fieldInfo.field.fromObj(loop_fieldInfo.val,skipIllegalChanges); }
				for (const loop_fieldInfo of pksToSetLast) { loop_fieldInfo.field.fromObj(loop_fieldInfo.val,skipIllegalChanges); }
			}
			catch (e)
			{
				this._throwEx(`Caught err while doing fromObj(): ${e}`, obj);
			}
		}
		/*
		Only outputs fields that are being used (ex with select_x())
		Can yield NULLs in some props, ex if they're not set, or completely ret undefined if options.onlyWithUnsavedChanges
		API directives (options.forAPICall):
			Check backend's Model_base::field_set() docs for more info
				Remove/unlink:  {pk:456, _apiDirective_:"<remove>"}
				Delete from DB: {pk:789, _apiDirective_:"<delete>"}
			When sub models have to be unlinked or removed from parent model or sub model list, we can use API directives to indicate that.
			For now though, we can only do that on sub models. Maybe one day we'd like to be able to delete parent models by doing the following, but looks weird:
				model.toDelete = true;
				const savedSomething = await model.awaitUnsavedChangesSaved({...});
		Check docs in toRemove() & toDelete() for when sub-models could get auto destroyed from parent models
		Unless we only want to keep nodes w unsaved changes and we don't have, extraData_x will also be transferred
		*/
		toObj(options={})
		{
			options = B_REST_Utils.object_hasValidStruct_assert(options, {
				onlyWithUnsavedChanges: {accept:[Boolean], default:false}, //Set to true ex for saving, and false if we just want to know all data
				forAPICall:             {accept:[Boolean], default:false}, //Set to true if it's meant for saving, ex to know when we have to delete stuff. Check the above docs
				filesOnly:              {accept:[Boolean], default:false}, //For when we have a !isNew model w B_REST_ModelField_File that has pendingUploads or files to delete we'd want to commit to the server, without commiting other fields changes. Should put onlyWithUnsavedChanges=true
			}, "B_REST_Model::toObj()");
			
			const obj = {};
			
			//Use API directive ?
			if (options.forAPICall && this.toRemoveOrDelete)
			{
				this._assert_isSubModel(); //Check method docs
				if (!this.pk_isSet) { this._throwEx(`Can't use API directive to indicate to remove or delete, because PK isn't set yet`); }
				
				//We must pass all PKs in the obj
				for (const loop_fieldDescriptor of this._descriptor.pks)
				{
					obj[loop_fieldDescriptor.name] = this._field_getAlloc_byDescriptor(loop_fieldDescriptor,/*allocate*/false).val;
				}
				
				obj[B_REST_Model.API_DIRECTIVE_TAG] = this._toDelete ? B_REST_Model.API_DIRECTIVE_DELETE : B_REST_Model.API_DIRECTIVE_REMOVE;
			}
			//Just output plain obj of all used fields
			else
			{
				for (const loop_fieldName in this._fieldData)
				{
					const loop_field = this._fieldData[loop_fieldName];
					if (loop_field===undefined) { continue; }
					
					if (options.filesOnly && !(loop_field instanceof B_REST_ModelFields.File)) { continue; }
					if (options.onlyWithUnsavedChanges && !loop_field.unsavedChanges_has)      { continue; }
					
					if (loop_field.isSet)
					{
						const loop_field_toObj = loop_field.toObj(options); //If we only want unsaved changes and we've got nothing, then this rets undefined, not NULL
						
						//If it did ret something diff than undefined, we'll indicate that it's NULL when we still care, otherwise discard it if !options.onlyWithUnsavedChanges
						if      (loop_field_toObj!==undefined)    { obj[loop_fieldName]=loop_field_toObj; }
						else if (!options.onlyWithUnsavedChanges) { obj[loop_fieldName]=null;             }
					}
					else { obj[loop_fieldName]=null; }
				}
				
				/*
				Check if we only wanted those w changes and we have none:
					When isAutoInc:
						PKs will never get in obj (but we can later w toObj_forSubModelList_addFrontendUUIDAndPKParts())
						If isNew, we should NEVER ret undefined, but rather an empty {}
					When !isAutoInc:
						If isNew, the PK fields will have unsaved changes (ex have just been set to {fk:123,lang:fr})
						If not new, then PK fields should have no unsaved changes
						So when isNew, obj will never be empty, so we should NEVER ret undefined
						And when !isNew, obj will always only contain other fields w unsaved changes
				*/
				if (options.onlyWithUnsavedChanges && B_REST_Utils.object_isEmpty(obj) && (!this._descriptor.isAutoInc||this.pk_isSet)) { return undefined; }
			}
			
			if (this._extraData_api!==null)                        { obj[B_REST_Model.API_EXTRA_DATA_BACKEND_FIELDNAME] =this._extraData_api; }
			if (this._extraData_ui !==null && !options.forAPICall) { obj[B_REST_Model.API_EXTRA_DATA_FRONTEND_FIELDNAME]=this._extraData_ui;  } //IMPORTANT: Don't send this to server, as ex if we put an instance of some class in there, and that server gets and rets it back, the instance will be converted to an stdObj and code will break
			
			return obj;
		}
			//For B_REST_ModelList::toObj()
			toObj_forSubModelList_addFrontendUUIDAndPKParts(obj)
			{
				obj[B_REST_Model.API_UID_FIELDNAME] = this._frontendUUID;
				
				for (const loop_fieldDescriptor of this._descriptor.pks)
				{
					const loop_fieldName = loop_fieldDescriptor.name;
					const loop_fieldVal  = this._fieldData[loop_fieldName]?.val ?? null;
					
					if (loop_fieldVal!==undefined && loop_fieldVal!==null) { obj[loop_fieldName]=loop_fieldVal; }
				}
			}
	
	
	
	//LOAD RELATED
		/*
		Helper to B_REST_Descriptor::load_list(). Check its docs
		Usage ex:
			commonDefs_load_list("Citizen", {
				apiBaseUrl: "/citizens/",
			})
		*/
		static async commonDefs_load_list(name, options)
		{
			const descriptor = B_REST_Descriptor.commonDefs_get(name);
			return descriptor.load_list(options);
		}
		/*
		Shortcut to using a B_REST_ModelList to ret a single instance
		Check B_REST_Descriptor::load_one() docs
		Contrary to commonDefs_load_one_uniqueKey() where we specify fields to WHERE, this one is more intended for passing path vars in options
		Usage ex:
			commonDefs_load_one("PackageTier", {
				apiBaseUrl:           "/config/regions/{region}/packageTiers/{type}/"
				apiBaseUrl_path_vars: {region:1,type:"brandMembership"}
			})
		NOTES:
			-Also accepts complicated things like "/config/regions/{region}/packageTiers/{type}/"
			-Doesn't throw if it'd ret more than 1 match
			-Use the options to override default of throwing on not found
		*/
		static async commonDefs_load_one(name, options)
		{
			const descriptor = B_REST_Descriptor.commonDefs_get(name);
			const ret = await descriptor.load_one(options);
			
			return ret ? ret.model : null;
		}
			//Shortcut to load_one() + filtering by multiple fields (specifying WHEREs)
			static async commonDefs_load_one_uniqueKey(name, fieldMap, options)
			{
				const descriptor = B_REST_Descriptor.commonDefs_get(name);
				const ret = await descriptor.load_one_uniqueKey(fieldMap, options);
				
				return ret ? ret.model : null;
			}
		//For loading lists, use an instance of B_REST_ModelList
	
	
	
	//SAVE RELATED
		//Helper not used in this model
		get saving_can()          { return this._saving_can_x("validation_isValid");          }
		get saving_can_fastOnly() { return this._saving_can_x("validation_isValid_fastOnly"); }
			_saving_can_x(validation_isValid_getterName)
			{
				return this._savePromise===null && !this.isSaving_hostModelField && this.isMutable && this[validation_isValid_getterName] && !this.fields_haveAsyncTasks;
			}
		//Only for self
		get isSaving() { return this._savePromise!==null; }
		//If it's a sub model in a parent B_REST_ModelField_ModelLookupRef / B_REST_ModelField_SubModel_base
		get isSaving_hostModelField()
		{
			if (!this._hostModelField) { return null; }
			
			return this._hostModelField.parentModel.isSaving || this._hostModelField.parentModel.isSaving_hostModelField || false;
		}
		/*
		Should use before saving (actually called in _awaitUnsavedChangesSaved())
		For now it's only for B_REST_ModelField_File w uploads currently transfering
		Keeps checking until all fields have no more things to do at the same moment
		*/
		async fields_awaitAsyncTasks()
		{
			do
			{
				for (const loop_fieldName in this._fieldData)
				{
					const loop_field = this._fieldData[loop_fieldName];
					if (loop_field) { await loop_field.awaitAsyncTasks(); }
				}
			}
			while (this.fields_haveAsyncTasks);
		}
		//Check fields_awaitAsyncTasks() docs
		get fields_haveAsyncTasks()
		{
			for (const loop_fieldName in this._fieldData)
			{
				const loop_field = this._fieldData[loop_fieldName];
				if (loop_field && loop_field.hasAsyncTasks) { return true; }
			}
			
			return false;
		}
		
		/*
		Automation of calling toObj() + doing an API call + parsing its response back into fromObj() + unflagging unsaved data, for creation and update
		Resolves when all tasks & hooks are done, indicating if something was saved, or rejects on err
		Waits for fields async ongoing tasks (ex B_REST_ModelField_File ongoing uploads transfers)
		If already saving when we call awaitUnsavedChangesSaved(), will just wait until ongoing save is done and return its promise
		Possible options:
			{
				apiBaseUrl:                  Ex "/brands/" when isNew or "/brands/{pkTag}" when not. Also accepts complicated things like "/config/regions/{region}/packageTiers/{type}/", as long as we def the path vars in apiBaseUrl_path_vars
				apiBaseUrl_path_vars:        Usually empty when isNew and {pkTag} when not (unless it's a non AUTO_INC model), otherwise ex like {a,b,pkTag} for an apiBaseUrl like "/clients/{pkTag}/invoices/{a}/notes/{b}"
				apiBaseUrl_needsAccessToken: Check notes in B_REST_Request_base::_needsAccessToken var def. Either bool or B_REST_Request_base::NEEDS_ACCESS_TOKEN_DONT (ex for login calls)
				beforeSave:                  Async hook that can be called, as (request<B_REST_Request>,   model<B_REST_Model>). Check docs below
				afterSave:                   Async hook that can be called, as (response<B_REST_Response>, model<B_REST_Model>, isSuccess, wasNew). Check docs below
				methodHint_isCreating:       Bool. Check warning below
				uploadProgressCallback:      Check B_REST_API::call() docs + warnings below
				downloadProgressCallback:    Check B_REST_API::call() docs + warnings below
			}
		Might throw for multiple reasons
		Before save hook:
			Can be used to tweak the B_REST_Request data before actually doing the call, ex if we want to alt someone's pwd, and we need to provide to the post the actual + new pwd.
			We would manually add these 2 fields, as they shouldn't be returned from the server in the first place
			Helps waiting for pending uploads to finish
			Always called, even if we have no unsaved changes when we enter the save func
		After save hook:
			We have already put back data in the model if possible, and while the B_REST_Response is still available, we allow to use it some more
		WARNINGS:
			-Breaks on validation errs
			-Since we do a fromObj() back on the model after the API call (if we don't get a 204), it could cause problems if the call doesn't return data at all
			-If this model has no AUTO_INC, then we have no way of telling if record exists in DB yet or no, so pass the option methodHint_isCreating=<bool> to precise whether to do a POST or PATCH
			-We unflag unsaved changes before calling after save hooks, so if we alter anything more there, we'll have to unflag it ourselves with unsavedChanges_unflag() in fields, etc
			-We can't guarantee that:
				-Sent obj will make sense with what that API path expects
				-Ret obj would fit with described fields and sub models
				-> Impacts in B_REST_Descriptor::load_list(), B_REST_Model::awaitUnsavedChangesSaved() & B_REST_ModelList::_load()
		*/
		async awaitUnsavedChangesSaved(options) { return this._awaitUnsavedChangesSaved(options, /*filesOnly*/false); }
		//Variant where we only commit B_REST_ModelField_File changes, ignoring general validation. Only works for isAutoInc that already exists in DB
		async awaitUnsavedChangesSaved_filesOnly(options)
		{
			if (this.isNew) { this._throwEx(`Must already exist to save files changes`); } //NOTE: Will throw if !isAutoInc; but we don't really handle that yet
			
			return this._awaitUnsavedChangesSaved(options, /*filesOnly*/true);
		}
			async _awaitUnsavedChangesSaved(options, filesOnly)
			{
				options = B_REST_Utils.object_hasValidStruct_assert(options, {
					apiBaseUrl:                  {accept:[String],       required:true},
					apiBaseUrl_path_vars:        {accept:[null,Object],  default:null},
					apiBaseUrl_needsAccessToken: {accept:[null,Boolean], default:null},
					uploadProgressCallback:      {accept:undefined,      default:null},
					downloadProgressCallback:    {accept:undefined,      default:null},
					beforeSave:                  {accept:undefined,      default:null},
					afterSave:                   {accept:undefined,      default:null},
					methodHint_isCreating:       {accept:[null,Boolean], default:null},
				}, "Model save options");
				
				if (this._savePromise) { return this._savePromise; }
				
				let promise_s = null;
				let promise_f = null;
				const savePromise = new Promise((s,f) =>
				{
					promise_s = s;
					promise_f = f;
				});
				this._savePromise = savePromise;
				
				const finalize = (success, ifSuccess_savedSomething=null, ifError_msg=null,ifError_details=null) =>
				{
					if (success) { promise_s(ifSuccess_savedSomething); }
					else
					{
						B_REST_Utils.console_error(`Got error saving: ${ifError_msg}`,ifError_details);
						promise_f(ifError_msg);
					}
					
					this._savePromise = null;
					
					return savePromise;
				};
				
				//If immutable, no need to do nothing more
				if (!this.isMutable) { return finalize(false,null,`Can't save, because is immutable`); }
				
				try
				{
					//Wait for things like B_REST_ModelField_File ongoing file transfers to finish first
					await this.fields_awaitAsyncTasks(); //Could throw (ex depending on B_REST_ModelField_File.AWAIT_DIE_ON_FAILED_TRANSFERS)
					
					//Unless we only care about applying B_REST_ModelField_File changes, make sure all is ok. We must recall all async validation
					if (!filesOnly)
					{
						await this.validation_custom_recalc_wait(/*recurseUp*/false, /*recurseDown*/true);
						
						const errMsgs = this.validation_getErrors(/*detailed*/false,/*onlyOne*/false,/*includeAsyncCustomErrors*/true);
						if (errMsgs.length>0)
						{
							const errDetails = this.validation_getErrors(/*detailed*/true, /*onlyOne*/false,/*includeAsyncCustomErrors*/true);
							
							return finalize(false, null, `Can't save, because some data isn't valid:\n\t${errMsgs.join("\n\t")}`,errDetails);
						}
					}
					
					//Now get what we have to create / save
					let objData = this.toObj({onlyWithUnsavedChanges:true,forAPICall:true,filesOnly}); //If we only want unsaved changes and we've got nothing, then this rets undefined, not NULL
					if (objData===undefined)
					{
						if (B_REST_Model.SAVE_SKIP_USELESS_CALLS)
						{
							B_REST_Utils.console_info(`Didn't call the save API, because SAVE_SKIP_USELESS_CALLS`);
							return finalize(true, false);
						}
						
						objData = {};
					}
					
					const isCreating = options.methodHint_isCreating ?? (this._descriptor.isAutoInc?!this.pk_isSet:null); //Hint yielding to if we'll do POST or PATCH
					if (isCreating===null) { return finalize(false, null, `Can't figure out whether or not to do a POST vs PATCH. Consider using the methodHint_isCreating option`); }
					
					/*
					Now figure out which method we'll need to do. Will mostly correspond to isCreating 99% of the time
					Eventually, we might need to add a methodHint_verb or something instead of / in complementary to methodHint_isCreating
					*/
					const method = isCreating ? "POST" : "PATCH";
					
					const request            = new B_REST_App_base.instance[method](options.apiBaseUrl, options.apiBaseUrl_path_vars); //Does either "new B_REST_App_base.POST()" or "new B_REST_App_base.PATCH()"
					request.data             = objData;
					request.needsAccessToken = options.apiBaseUrl_needsAccessToken;
					
					//Check if we have to pimp request
					try
					{
						if (this._descriptor.hook_save_before) { await this._descriptor.hook_save_before(request,this); }
						if (options.beforeSave)                { await options.beforeSave(request,this);                }
					}
					catch (e) { return finalize(false, null, `beforeSave hook failed`,e); }
					
					//Call the API; might throw for multiple reasons
					const requestOptions = {};
						if (options.uploadProgressCallback)   { requestOptions.uploadProgressCallback   = options.uploadProgressCallback;   }
						if (options.downloadProgressCallback) { requestOptions.downloadProgressCallback = options.downloadProgressCallback; }
					const response = await B_REST_App_base.instance.call(request, requestOptions); //Might throw
					
					/*
					Pass back the received data in the model, if we've got some
					WARNING: If the call doesn't ret data anyways, we'll have a prob. If ever that's the case, we'll need new options
					*/
					if (response.isSuccess)
					{
						if (response.isSuccess_nonEmpty)
						{
							const response_data_item = response.data?.item;
							if (B_REST_Utils.object_is(response_data_item)) { this.fromObj(response_data_item,B_REST_Descriptor.FROM_OBJ_SKIP_ILLEGALS_SAVE); }
						}
						else { B_REST_Utils.console_warn(`Save call succeeded but didn't ret {item:{}}, for ${request.url_debug}`,response); }
						
						//Unflag unsaved data now, possibly also unlinking / deleting sub stuff
						this.unsavedChanges_unflagAllFields({cleanupDeletions:B_REST_Model.SAVE_CLEANUP_DELETIONS, filesOnly});
					}
					else { B_REST_Utils.console_warn(`Save call didn't ret success, for ${request.url_debug}`,response); }
					
					/*
					Check if we have to give the user access to the response (MIGHT BE NULL) + model to do final tweaks on fields
					WARNING:
						Since we've already unflagged unsaved changes, if we alter anything more there, we'll have to unflag it ourselves with unsavedChanges_unflag() in fields, etc
					*/
					try
					{
						if (this._descriptor.hook_save_after) { await this._descriptor.hook_save_after(response,this,isCreating);     }
						if (options.afterSave)                { await options.afterSave(response,this,response.isSuccess,isCreating); }
					}
					catch (e) { return finalize(false, null, `afterSave hook failed`,e); }
					
					//If we get here, it means we succeeded and we had to save something
					return finalize(true, true);
				}
				catch (e)
				{
					const msg = e instanceof B_REST_Response ? (e.debug_errorMsg||e.errorMsg) : e; //NOTE: debug_errorMsg is server's "X-B-REST-Error-Msg" header
					
					return finalize(false, null, `Got error in save: ${msg}`,e);
				}
			}
	
	
	/*
	BATCH API CALLS HELPER
		To run API call on multiple models at the same time, either specifying some B_REST_Model_Load_SearchOptions filters, explicit B_REST_Model instances or just PKs,
			ex to batch send emails, print stuff, change statuses or delete records
		Check related:
			Server's RouteParser_base::genericListFormModule_helper_batchAction() & RouteParser_base::_overridable_genericListFormModule_action_del()
			Frontend's B_REST_Descriptor::batchAPICall_x(), B_REST_Model::commonDefs_batchAPICall_x(), B_REST_ModelList::batchAPICall_x(), B_REST_ModelList::deleteSome(), BrGenericListBase::xActions_helper_batchAction_x(), B_REST_Vuetify_GenericList_Action::defineCommonAction_delete()
	*/
		async commonDefs_batchAPICall_GET(         name,options) { return B_REST_Descriptor.commonDefs_get(name).batchAPICall_GET(         options); }
		async commonDefs_batchAPICall_POST(        name,options) { return B_REST_Descriptor.commonDefs_get(name).batchAPICall_POST(        options); }
		async commonDefs_batchAPICall_PUT(         name,options) { return B_REST_Descriptor.commonDefs_get(name).batchAPICall_PUT(         options); }
		async commonDefs_batchAPICall_PATCH(       name,options) { return B_REST_Descriptor.commonDefs_get(name).batchAPICall_PATCH(       options); }
		async commonDefs_batchAPICall_DELETE(      name,options) { return B_REST_Descriptor.commonDefs_get(name).batchAPICall_DELETE(      options); }
		async commonDefs_batchAPICall_GET_File_csv(name,options) { return B_REST_Descriptor.commonDefs_get(name).batchAPICall_GET_File_csv(options); }
	
	
	
	//TO REMOVE & DELETE RELATED
		/*
		GENERAL NOTES:
			Check B_REST_Model::toObj() docs + for API directives
			For now, only for sub models, not top lvl ones
			Also, if this sub model was an AUTO_INC that has no PK yet, with ifNew_freeFromParent_now we can also auto destroy it (for now only if it was in a B_REST_ModelFields.SubModelList)
		*/
		get toRemoveOrDelete() { return this._toRemove || this._toDelete; }
		
		get toRemove()                               { return this._toRemove;                                                         }
		toRemove_flag(ifNew_freeFromParent_now=true) { this._toRemoveOrDelete_flag("_toRemove","_toDelete",ifNew_freeFromParent_now); }
		toRemove_unflag()                            { this._toRemoveOrDelete_unflag("_toRemove");                                    }
		
		get toDelete()                               { return this._toDelete;                                                         }
		toDelete_flag(ifNew_freeFromParent_now=true) { this._toRemoveOrDelete_flag("_toDelete","_toRemove",ifNew_freeFromParent_now); }
		toDelete_unflag()                            { this._toRemoveOrDelete_unflag("_toDelete");                                    }
			_toRemoveOrDelete_flag(thisVarName, oppositeVarName, ifNew_freeFromParent_now)
			{
				this._assert_isSubModel();
				if (!this.isMutable) { this._throwEx(`Can't flag to remove or to delete, because model is immutable now`); }
				if (this[oppositeVarName]) { this._throwEx(`Already flagged as ${oppositeVarName.replace("_","")}`); }
				
				this[thisVarName] = true;
				
				//Check if we have to remove it from a parent model's B_REST_ModelFields.SubModelList
				if (ifNew_freeFromParent_now && this._hostModelField instanceof B_REST_ModelFields.SubModelList && this._descriptor.isAutoInc && !this.pk_isSet)
				{
					this._hostModelField.destroy(this);
				}
			}
			_toRemoveOrDelete_unflag(thisVarName)
			{
				this._assert_isSubModel();
				if (!this.isMutable) { this._throwEx(`Can't unflag to remove or to delete, because model is immutable now`); }
				this[thisVarName] = false;
			}
				_assert_isSubModel() { if(!this._hostModelField){this._throwEx(`Can only do that on sub models (where hostModelField is defined)`);} }
		
		/*
		Ex, using API_PATH_VARS_PK_TAG:
		{
			apiBaseUrl:           clients/{pkTag}
			apiBaseUrl_path_vars: {pkTag:123}
		}
		Resolves on success, otherwise rejects (perms & general errors)
		NOTE:
			If wanting to del multiple at the same time, check B_REST_ModelList::deleteSome() too
			And if using Vue implementation's BrGenericListBase, consider using its xActions_helper_batchAction_() or B_REST_Vuetify_GenericList_Action_base::defineCommonAction_delete()
		*/
		static async delete(options)
		{
			options = B_REST_Utils.object_hasValidStruct_assert(options, {
				apiBaseUrl:                  {accept:[String],       required:true},
				apiBaseUrl_path_vars:        {accept:[null,Object],  default:null},
				apiBaseUrl_needsAccessToken: {accept:[null,Boolean], default:true},
			}, "Model delete options");
			
			return new Promise(async (s,f) =>
			{
				try
				{
					const request            = new B_REST_App_base.instance.DELETE(options.apiBaseUrl, options.apiBaseUrl_path_vars);
					request.needsAccessToken = options.apiBaseUrl_needsAccessToken;
					
					//Call the API; might throw for multiple reasons
					const requestOptions = {};
					const response       = await B_REST_App_base.instance.call(request, requestOptions); //Might throw
					
					if (response.isSuccess) { s();return; }
					
					B_REST_Utils.console_warn(`Delete call didn't ret success, for ${request.url_debug}`,response);
					f(`Couldn't del`);
				}
				catch (e) { f(`Got exception deleting: ${e}`); }
			});
		}
	
	
	
	//SHARED / CACHED RELATED
		/*
		Puts a B_REST_Model instance to the shared models cache, as long as its PK is known
		Rets if it got cached
		We then set its isInCachedShare prop to true
		WARNING:
			-We ignore the fact that we might have put to cache an instance with fields A,B,C "loaded", and that a usage expects fields B,C,D to be "used"
			-We ignore that it's possible 2 instances of the same PK are put to cache, each with diff fields loaded (check the above).
				The latter will replace the 1st, if that's what we want, and a logic choice (overwriteIfMoreFieldsSet)
		*/
		static cachedShare_put(model, overwriteIfMoreFieldsSet)
		{
			B_REST_Utils.instance_isOfClass_assert(B_REST_Model, model);
			
			if (!model.pk_isSet) { B_REST_Model._throwEx(`Can't put to cached share models w/o set PK. If it was for a lookup model, make sure its fromObj() received its PK first`,model); }
			
			const modelName = model.descriptor.name;
			const pkTag     = model.pk_tag;
			
			//Check if set doesn't exist yet
			if (!B_REST_Model._cachedShare[modelName]) { B_REST_Model._cachedShare[modelName] = {models:{}}; }
			
			//Check if we have to confirm an overwrite
			const previousModel = B_REST_Model._cachedShare[modelName].models[pkTag];
			if (previousModel && overwriteIfMoreFieldsSet)
			{
				const previousModel_count = previousModel._field_allocatedCount(B_REST_Model.CACHED_SHARE_PUT_ALLOCATED_COUNT_NEST);
				const newModel_count      = model._field_allocatedCount(B_REST_Model.CACHED_SHARE_PUT_ALLOCATED_COUNT_NEST);
				
				if (newModel_count <= previousModel_count) { return false; }
				
				/*
				Prefer this, than completely replacing the obj, otherwise models pointing on the previous model will not see the data changes reflected
				Also unflag it out from cached share temporarily, so we don't get errs while putting more data (since isMutable becomes false)
				*/
				previousModel._isInCachedShare = false;
				previousModel.fromObj(model.toObj({onlyWithUnsavedChanges:false,forAPICall:false}), B_REST_Descriptor.FROM_OBJ_SKIP_ILLEGALS_SHARED_UPDATE);
				previousModel._isInCachedShare = true;
			}
			else
			{
				B_REST_Model._cachedShare[modelName].models[pkTag] = model;
				model._isInCachedShare = true;
			}
			
			return true;
		}
		/*
		Check warnings above in cachedShare_put(). We won't make sure we do have the required fields we wanted
		Rets NULL if it doesn't exist yet in cache
		*/
		static cachedShare_get(modelName, pkTag)
		{
			return B_REST_Model._cachedShare[modelName]?.models[pkTag] ?? null;
		}
};
