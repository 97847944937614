<template>
	<br-generic-form-base :derived-component="_self">
		
		<template #fields>
			<v-row no-gutters>
				<v-col cols="12">{{ model.select("createdDT").val }}</v-col>
				<v-col cols="12"> <br-field-db :model="model" field="isNew" as="checkbox" /> </v-col>
				<v-col cols="12"> <br-field-file :model="model" field="output" /> </v-col>
			</v-row>
		</template>
		
	</br-generic-form-base>
</template>

<script>
	
	import { B_REST_Vuetify_GenericFormBase_createMixin } from "@/bREST/core/implementations/vue/vuetifyComponents/genericModules/form/BrGenericFormBase.vue";
	
	export default {
		name: "postalMailInvoiceBatchForm",
		components: {
			FkPickerWViewAddBtns: ()=>import("@/custom/components/FkPickerWViewAddBtns.vue"),
		},
		mixins: B_REST_Vuetify_GenericFormBase_createMixin({
			modelName:          "PostalMailInvoiceBatch",
			apiBaseUrl:         "/postalMailInvoiceBatches/",
			needsAccessToken:   true,
			showValidationErrs: true,
			showSkeletonLoader: true,
		 // autoUpdateInterval: 2000,
			requiredFields:     "<all>",
			todos: [
				//Arr of {isDone,isBug,text} that will appear automatically at the top of the form
			],
			async modelReady()
			{
				const description = this.model.select("description").val;
				const fileField   = this.model.select("output");
				
				fileField.isReadOnly = true;
				
				//Hack to change file name, for now (not yet possible in bREST)
				fileField.control.items[0].fileInfo.baseNameWExt = `${description}.pdf`;
			},
			async afterLoad(response,models)                 { },
			async customValidator()                          { },
			async beforeSave(request,model)                  { },
			async afterSave(response,model,isSuccess,wasNew) { },
		}),
		methods: {
			intervention_onSelect({modelField,selection})
			{
				
			},
			animal_onSelect({modelField,selection})
			{
				
			},
			owner_onSelect({modelField,selection})
			{
				
			},
		},
	}
	
</script>