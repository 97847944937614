<template>
	<br-app-booter text-color="white" progressbar-color="#bc955a" :logo="logo" :logo-width="400" background-color="#1a1e2a" :background-image="backgroundImage">
		<!--
			NOTE: We can override the default slot like this: Check BrAppBooter.vue for more info
				<template #default="{isSpinning, errorMsg, reboot}">...</template>
		-->
	</br-app-booter>
</template>
<script>
	
	export default {
		data()
		{
			return {
				logo: require("@/custom/assets/logo-transparent.png"),
				backgroundImage: require("@/custom/assets/splashscreen--vertical--greyscale.png"),
			};
		},
	};
	
</script>
<style> @import './App.css'; </style>